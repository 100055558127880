export const definitions = {
  'RoleIdPermission': {
    'required': ['permissionType', 'roleId'],
    'type': 'object',
    'properties': {
      'roleId': {
        'type': 'integer',
        'format': 'int64'
      },
      'permissionType': {
        'type': 'string',
        'enum': ['VIEW', 'USE', 'EDIT']
      }
    }
  },
  'RuleField': {
    'required': ['dataType', 'isRequired', 'name'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'isRequired': {
        'type': 'boolean'
      },
      'dataType': {
        'type': 'string',
        'enum': ['NUMBER', 'INTEGER', 'LONG', 'DOUBLE', 'BOOLEAN', 'STRING', 'TIMESTAMP', 'FILE_JSON', 'FILE_XML', 'ARRAY', 'OBJECT']
      },
      'genericSubType': {
        'type': 'string',
        'enum': ['NUMBER', 'INTEGER', 'DOUBLE', 'BOOLEAN', 'STRING', 'TIMESTAMP', 'OBJECT']
      },
      'validationRuleId': {
        'type': 'integer',
        'format': 'int64'
      },
      'rules': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RuleValue'
        }
      }
    }
  },
  'RuleValue': {
    'required': ['type', 'value'],
    'type': 'object',
    'properties': {
      'type': {
        'type': 'string',
        'enum': ['MIN_LENGTH', 'MAX_LENGTH', 'MIN_VALUE', 'MAX_VALUE', 'MIN_SIZE', 'MAX_SIZE', 'REGEX']
      },
      'value': {
        'type': 'object'
      }
    }
  },
  'ValidationRuleRequest': {
    'required': ['fields', 'name', 'roles'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'fields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RuleField'
        }
      },
      'includes': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'type': 'integer',
          'format': 'int64'
        }
      }
    }
  },
  'ResourceRole': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'role': {
        '$ref': '#/definitions/Role'
      },
      'permissionType': {
        'type': 'string',
        'enum': ['VIEW', 'USE', 'EDIT']
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      }
    }
  },
  'Role': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'isSystem': {
        'type': 'boolean'
      },
      'isAssignableToUser': {
        'type': 'boolean'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      }
    }
  },
  'ValidationRule': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'fields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RuleField'
        }
      },
      'includes': {
        'type': 'array',
        'items': {
          'type': 'integer',
          'format': 'int64'
        }
      },
      'entityId': {
        'type': 'integer',
        'format': 'int64'
      },
      'isSystem': {
        'type': 'boolean'
      }
    }
  },
  'ResourceRolesRequest': {
    'required': ['roles'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      }
    }
  },
  'UserRequest': {
    'required': ['email', 'name', 'roles', 'status'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'email': {
        'type': 'string'
      },
      'roles': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'type': 'integer',
          'format': 'int64'
        }
      },
      'allowedLoginAttempts': {
        'maximum': 64,
        'minimum': 1,
        'type': 'integer',
        'format': 'int32'
      },
      'allowedApiAttempts': {
        'maximum': 64,
        'minimum': 1,
        'type': 'integer',
        'format': 'int32'
      }
    }
  },
  'User': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'email': {
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Role'
        }
      },
      'twoFactorAuthEnabled': {
        'type': 'boolean'
      }
    }
  },
  'UserApiKey': {
    'type': 'object',
    'properties': {
      'apiKey': {
        'type': 'string'
      }
    }
  },
  'TriggerRestRequest': {
    'required': ['isGdprRelevant', 'maxRequestSize', 'method', 'name', 'processId', 'roles', 'status', 'url'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'priority': {
        'type': 'integer',
        'format': 'int32'
      },
      'instanceIds': {
        'maxItems': 48,
        'minItems': 0,
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'maxLength': 48,
          'minLength': 0,
          'type': 'string'
        }
      },
      'url': {
        'type': 'string'
      },
      'method': {
        'type': 'string',
        'enum': ['GET', 'POST', 'PUT', 'PATCH', 'DELETE']
      },
      'maxRequestSize': {
        'type': 'integer',
        'description': 'In bytes',
        'format': 'int32'
      },
      'validationRules': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerValidationRuleRequest'
        }
      },
      'basicAccessAuthentication': {
        'type': 'boolean'
      }
    }
  },
  'TriggerValidationRuleRequest': {
    'required': ['type', 'validationRuleId'],
    'type': 'object',
    'properties': {
      'validationRuleId': {
        'type': 'integer',
        'format': 'int64'
      },
      'type': {
        'type': 'string',
        'enum': ['QUERY_PARAMS', 'HEADERS', 'BODY', 'PATH_VARIABLES']
      }
    }
  },
  'TriggerRest': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'priority': {
        'type': 'integer',
        'format': 'int32'
      },
      'instanceIds': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'url': {
        'type': 'string'
      },
      'method': {
        'type': 'string',
        'enum': ['GET', 'POST', 'PUT', 'PATCH', 'DELETE']
      },
      'maxRequestSize': {
        'type': 'integer',
        'format': 'int32'
      },
      'basicAccessAuthentication': {
        'type': 'boolean'
      },
      'entityId': {
        'type': 'integer',
        'format': 'int64'
      },
      'validationRules': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerValidationRule'
        }
      },
      'isSystem': {
        'type': 'boolean'
      }
    }
  },
  'TriggerValidationRule': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'type': {
        'type': 'string',
        'enum': ['QUERY_PARAMS', 'HEADERS', 'BODY', 'PATH_VARIABLES']
      },
      'validationRule': {
        '$ref': '#/definitions/ValidationRule'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      }
    }
  },
  'TriggerMessagingRequest': {
    'required': ['credentialId', 'isGdprRelevant', 'name', 'processId', 'roles', 'service', 'status'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'priority': {
        'type': 'integer',
        'format': 'int32'
      },
      'instanceIds': {
        'maxItems': 48,
        'minItems': 0,
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'maxLength': 48,
          'minLength': 0,
          'type': 'string'
        }
      },
      'service': {
        'type': 'string',
        'enum': ['KAFKA', 'JMS', 'RABBITMQ', 'AWS_SQS', 'MQTT']
      },
      'credentialId': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'TriggerMessaging': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'priority': {
        'type': 'integer',
        'format': 'int32'
      },
      'instanceIds': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'service': {
        'type': 'string',
        'enum': ['KAFKA', 'JMS', 'RABBITMQ', 'AWS_SQS', 'MQTT']
      },
      'credentialId': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'TriggerEventHandlerRequest': {
    'required': ['isGdprRelevant', 'listenStatus', 'listenType', 'name', 'processId', 'roles', 'status'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'priority': {
        'type': 'integer',
        'format': 'int32'
      },
      'instanceIds': {
        'maxItems': 48,
        'minItems': 0,
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'maxLength': 48,
          'minLength': 0,
          'type': 'string'
        }
      },
      'listenType': {
        'type': 'string',
        'enum': ['REST', 'CRON', 'RUN_PROCESS', 'MESSAGING', 'EVENT_HANDLER', 'ALL']
      },
      'listenStatus': {
        'type': 'string',
        'enum': ['FAILED', 'TIMEOUT', 'ALL']
      },
      'listenProcessId': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'TriggerEventHandler': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'priority': {
        'type': 'integer',
        'format': 'int32'
      },
      'instanceIds': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'listenType': {
        'type': 'string',
        'enum': ['REST', 'CRON', 'RUN_PROCESS', 'MESSAGING', 'EVENT_HANDLER', 'ALL']
      },
      'listenStatus': {
        'type': 'string',
        'enum': ['FAILED', 'TIMEOUT', 'ALL']
      },
      'listenProcessId': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'TriggerCronRequest': {
    'required': ['isGdprRelevant', 'name', 'processId', 'roles', 'status', 'triggerCondition'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'priority': {
        'type': 'integer',
        'format': 'int32'
      },
      'instanceIds': {
        'maxItems': 48,
        'minItems': 0,
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'maxLength': 48,
          'minLength': 0,
          'type': 'string'
        }
      },
      'triggerCondition': {
        'type': 'string'
      }
    }
  },
  'TriggerCron': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'priority': {
        'type': 'integer',
        'format': 'int32'
      },
      'instanceIds': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'triggerCondition': {
        'type': 'string'
      }
    }
  },
  'TranslationRequest': {
    'required': ['language', 'name', 'roles', 'text'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'language': {
        'maxLength': 2,
        'minLength': 2,
        'type': 'string'
      },
      'text': {
        'type': 'string'
      }
    }
  },
  'TextTranslation': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'language': {
        'type': 'string'
      },
      'text': {
        'type': 'string'
      }
    }
  },
  'TextTemplateRequest': {
    'required': ['body', 'engine', 'name', 'roles'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'body': {
        'type': 'string'
      },
      'engine': {
        'type': 'string',
        'enum': ['THYMELEAF', 'MUSTACHE', 'VELOCITY', 'HANDLEBARS', 'MARKDOWN']
      },
      'exampleVariables': {
        'type': 'object',
        'additionalProperties': {
          'type': 'object'
        }
      }
    }
  },
  'TextTemplateFull': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'isSystem': {
        'type': 'boolean'
      },
      'body': {
        'type': 'string'
      },
      'engine': {
        'type': 'string',
        'enum': ['THYMELEAF', 'MUSTACHE', 'VELOCITY', 'HANDLEBARS', 'MARKDOWN']
      },
      'exampleVariables': {
        'type': 'object'
      }
    }
  },
  'TemplateTranslationRequest': {
    'required': ['language', 'name', 'text'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'language': {
        'type': 'string'
      },
      'text': {
        'type': 'string'
      }
    }
  },
  'StorageUpsertRequest': {
    'required': ['key', 'processId', 'value'],
    'type': 'object',
    'properties': {
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'key': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'value': {
        'type': 'object'
      },
      'expireOn': {
        'type': 'string',
        'format': 'date-time'
      }
    }
  },
  'Storage': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'processName': {
        'type': 'string'
      },
      'key': {
        'type': 'string'
      },
      'expireOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      }
    }
  },
  'SettingRequestMapStringObject': {
    'required': ['name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        'type': 'object',
        'additionalProperties': {
          'type': 'object'
        }
      }
    }
  },
  'Setting': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'type': {
        'type': 'string',
        'enum': ['INSTANCE_PROCESSING', 'INSTANCE_ADMIN', 'PROCESS', 'PLUGIN']
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        'type': 'object'
      },
      'isSystem': {
        'type': 'boolean'
      }
    }
  },
  'InstanceProcessingSetting': {
    'type': 'object',
    'properties': {
      'maxTriggerHttpThreads': {
        'type': 'integer',
        'format': 'int32'
      },
      'maxTriggerHttpProcessingInMSec': {
        'maximum': 3600000,
        'type': 'integer',
        'format': 'int32'
      },
      'maxProcessingThreads': {
        'type': 'integer',
        'format': 'int32'
      },
      'maxEmailSendingThreads': {
        'type': 'integer',
        'format': 'int32'
      }
    }
  },
  'SettingRequestInstanceProcessingSetting': {
    'required': ['name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/InstanceProcessingSetting'
      }
    }
  },
  'SettingRequestObject': {
    'required': ['name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        'type': 'object'
      }
    }
  },
  'RoleRequest': {
    'required': ['name'],
    'type': 'object',
    'properties': {
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      }
    }
  },
  'ProcessRequest': {
    'required': ['isDebugLogEnabled', 'isGdprRelevant', 'name', 'roles', 'status', 'steps'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'overallSimultaneousExecutions': {
        'minimum': 1,
        'type': 'integer',
        'format': 'int32'
      },
      'simultaneousExecutionsPerInstance': {
        'minimum': 1,
        'type': 'integer',
        'format': 'int32'
      },
      'maxProcessingDurationInMSec': {
        'maximum': 604800000,
        'minimum': 500,
        'type': 'integer',
        'format': 'int32'
      },
      'logsTtlInMSec': {
        'maximum': 315360000000,
        'minimum': 0,
        'type': 'integer',
        'format': 'int64'
      },
      'errorsTtlInMSec': {
        'maximum': 315360000000,
        'type': 'integer',
        'format': 'int64'
      },
      'steps': {
        '$ref': '#/definitions/ProcessSteps'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'isStepLogsEnabled': {
        'type': 'boolean'
      },
      'isCacheStepLogsEnabled': {
        'type': 'boolean'
      },
      'isDebugLogEnabled': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'inputValidationRuleId': {
        'type': 'integer',
        'format': 'int64'
      },
      'outputValidationRuleId': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'ProcessSteps': {
    'required': ['steps'],
    'type': 'object',
    'properties': {
      'scriptEngine': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      },
      'steps': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/StepStepProperties'
        }
      }
    }
  },
  'StepProperties': {
    'type': 'object'
  },
  'StepStepProperties': {
    'required': ['name', 'properties', 'type'],
    'type': 'object',
    'properties': {
      'id': {
        'minimum': 1,
        'type': 'integer',
        'format': 'int32'
      },
      'name': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['MONGODB', 'JDBC', 'MESSAGING', 'FOREACH', 'SWITCH', 'WHILE', 'TRY_CATCH', 'JS', 'GROOVY', 'PYTHON', 'UNSET_VARIABLES', 'EMAIL', 'SLACK', 'TWILIO', 'PAYMENT_SENSE_PAC', 'PAYMENT_SENSE_CONNECT_E', 'EXECUTE_PROCESS', 'PROCESS_SETTING', 'USER', 'PLUGIN', 'CREDENTIAL', 'REST', 'JWT', 'SECURITY', 'S3', 'IMAP', 'PDF', 'CSV', 'IMAGE', 'UUID', 'EXECUTE_EXTERNAL_COMMAND', 'QUERY_BUILDER', 'ZIP', 'LOG', 'ENCODER', 'STORAGE', 'FORMATTING', 'XSLT', 'GRAPHQL', 'ESC_CHARS', 'FTP', 'XML', 'OCR', 'QR', 'CHAT_GPT', 'LDAP', 'SSH', 'XLS', 'YAML', 'I_CALENDAR', 'OUTLOOK_CALENDAR']
      },
      'enabled': {
        'type': 'boolean'
      },
      'enableStepLog': {
        'type': 'boolean'
      },
      'enableCacheLog': {
        'type': 'boolean'
      },
      'enableDebugLog': {
        'type': 'boolean'
      },
      'properties': {
        '$ref': '#/definitions/StepProperties'
      }
    }
  },
  'ProcessFull': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'isSystem': {
        'type': 'boolean'
      },
      'overallSimultaneousExecutions': {
        'type': 'integer',
        'format': 'int32'
      },
      'simultaneousExecutionsPerInstance': {
        'type': 'integer',
        'format': 'int32'
      },
      'maxProcessingDurationInMSec': {
        'type': 'integer',
        'format': 'int32'
      },
      'logsTtlInMSec': {
        'type': 'integer',
        'format': 'int64'
      },
      'errorsTtlInMSec': {
        'type': 'integer',
        'format': 'int64'
      },
      'steps': {
        '$ref': '#/definitions/ProcessSteps'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'isStepLogsEnabled': {
        'type': 'boolean'
      },
      'isCacheStepLogsEnabled': {
        'type': 'boolean'
      },
      'isDebugLogEnabled': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'inputValidationRuleId': {
        'type': 'integer',
        'format': 'int64'
      },
      'outputValidationRuleId': {
        'type': 'integer',
        'format': 'int64'
      },
      'entityId': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'CredentialFieldToEncrypt': {
    'required': ['field', 'type'],
    'type': 'object',
    'properties': {
      'field': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['PLAIN', 'ENCRYPT', 'ENCRYPTED']
      }
    }
  },
  'ProcessCredentialRequestTwilioCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/TwilioCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'TwilioCredentialValues': {
    'required': ['sid', 'token'],
    'type': 'object',
    'properties': {
      'sid': {
        'type': 'string'
      },
      'token': {
        'type': 'string'
      }
    }
  },
  'CredentialFieldEncryptionStatus': {
    'required': ['field', 'type'],
    'type': 'object',
    'properties': {
      'field': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['PLAIN', 'ENCRYPTED']
      }
    }
  },
  'ProcessCredential': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'type': {
        'type': 'string',
        'enum': ['JDBC', 'MONGODB', 'TWILIO', 'SLACK', 'SMTP', 'IMAP', 'REST', 'REST_BASIC', 'REST_BEARER', 'PAYMENT_SENSE_PAC', 'PAYMENT_SENSE_CONNECT_E', 'FLOWY', 'PLUGIN', 'AWS', 'SECURITY', 'SCRIPT', 'JWT', 'OPEN_AI', 'LDAP', 'KAFKA', 'JMS', 'RABBITMQ', 'MQTT', 'FTP', 'SFTP', 'SSH', 'SSH_KEY', 'OUTLOOK_CALENDAR']
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'isSystem': {
        'type': 'boolean'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'adminOptions': {
        'type': 'object'
      },
      'values': {
        'type': 'object'
      }
    }
  },
  'ProcessCredentialRequestSshCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/SshCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'SshCredentialValues': {
    'required': ['host', 'username'],
    'type': 'object',
    'properties': {
      'host': {
        'type': 'string'
      },
      'username': {
        'type': 'string'
      },
      'port': {
        'type': 'integer',
        'format': 'int32'
      },
      'password': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestSshKeyCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/SshKeyCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'SshKeyCredentialValues': {
    'required': ['host', 'privateKey', 'username'],
    'type': 'object',
    'properties': {
      'host': {
        'type': 'string'
      },
      'username': {
        'type': 'string'
      },
      'port': {
        'type': 'integer',
        'format': 'int32'
      },
      'password': {
        'type': 'string'
      },
      'privateKey': {
        'type': 'string'
      },
      'passphrase': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestSmtpCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/SmtpCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'SmtpCredentialValues': {
    'required': ['host', 'password', 'port', 'username'],
    'type': 'object',
    'properties': {
      'host': {
        'type': 'string'
      },
      'port': {
        'type': 'integer',
        'format': 'int32'
      },
      'username': {
        'type': 'string'
      },
      'senderAddress': {
        'type': 'string'
      },
      'password': {
        'type': 'string'
      },
      'connectionTimeout': {
        'type': 'integer',
        'format': 'int32'
      },
      'timeout': {
        'type': 'integer',
        'format': 'int32'
      },
      'writeTimeout': {
        'type': 'integer',
        'format': 'int32'
      }
    }
  },
  'ProcessCredentialRequestSlackCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/SlackCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'SlackCredentialValues': {
    'required': ['channelId', 'token'],
    'type': 'object',
    'properties': {
      'token': {
        'type': 'string'
      },
      'channelId': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestSftpCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/SftpCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'SftpCredentialValues': {
    'required': ['host', 'username'],
    'type': 'object',
    'properties': {
      'host': {
        'type': 'string'
      },
      'username': {
        'type': 'string'
      },
      'port': {
        'type': 'integer',
        'format': 'int32'
      },
      'password': {
        'type': 'string'
      },
      'privateKey': {
        'type': 'string'
      },
      'passphrase': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestSecurityCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/SecurityCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'SecurityCredentialValues': {
    'type': 'object',
    'properties': {
      'passphrase': {
        'type': 'string'
      },
      'key': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestMapStringObjectVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        'type': 'object',
        'additionalProperties': {
          'type': 'object'
        }
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'ProcessCredentialRequestRabbitmqCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/RabbitmqCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'RabbitmqCredentialValues': {
    'required': ['host', 'password', 'port', 'username'],
    'type': 'object',
    'properties': {
      'rootCertificate': {
        'type': 'string'
      },
      'rootCertificatePassword': {
        'type': 'string'
      },
      'clientCertificate': {
        'type': 'string'
      },
      'clientPrivateKey': {
        'type': 'string'
      },
      'host': {
        'type': 'string'
      },
      'port': {
        'type': 'integer',
        'format': 'int32'
      },
      'username': {
        'type': 'string'
      },
      'password': {
        'type': 'string'
      },
      'virtualHost': {
        'type': 'string'
      },
      'exchange': {
        'type': 'string'
      },
      'routingKey': {
        'type': 'string'
      }
    }
  },
  'PaymentSensePacCredentialValues': {
    'required': ['apiKey', 'hostUrl', 'installerId', 'softwareHouseId', 'username'],
    'type': 'object',
    'properties': {
      'hostUrl': {
        'type': 'string'
      },
      'username': {
        'type': 'string'
      },
      'apiKey': {
        'type': 'string'
      },
      'softwareHouseId': {
        'type': 'string'
      },
      'installerId': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestPaymentSensePacCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/PaymentSensePacCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'PaymentSenseConnectECredentialValues': {
    'required': ['hostUrl', 'jwt', 'merchantUrl'],
    'type': 'object',
    'properties': {
      'hostUrl': {
        'type': 'string'
      },
      'jwt': {
        'type': 'string'
      },
      'merchantUrl': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestPaymentSenseConnectECredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/PaymentSenseConnectECredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'OutlookCalendarCredentialValues': {
    'required': ['clientId', 'clientSecret', 'tenant'],
    'type': 'object',
    'properties': {
      'tenant': {
        'type': 'string'
      },
      'clientId': {
        'type': 'string'
      },
      'clientSecret': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestOutlookCalendarCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/OutlookCalendarCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'OpenAICredentialValues': {
    'required': ['token'],
    'type': 'object',
    'properties': {
      'token': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestOpenAICredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/OpenAICredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'MqttCredentialValues': {
    'required': ['host', 'port', 'scheme', 'topic'],
    'type': 'object',
    'properties': {
      'rootCertificate': {
        'type': 'string'
      },
      'rootCertificatePassword': {
        'type': 'string'
      },
      'clientCertificate': {
        'type': 'string'
      },
      'clientPrivateKey': {
        'type': 'string'
      },
      'scheme': {
        'type': 'string',
        'enum': ['TCP', 'MQTT', 'MQTTS', 'WEBSOCKETS', 'SSL']
      },
      'host': {
        'type': 'string'
      },
      'port': {
        'type': 'integer',
        'format': 'int32'
      },
      'topic': {
        'type': 'string'
      },
      'username': {
        'type': 'string'
      },
      'password': {
        'type': 'string'
      },
      'clientId': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestMqttCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/MqttCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'MongodbCredentialValues': {
    'required': ['url'],
    'type': 'object',
    'properties': {
      'url': {
        'type': 'string'
      },
      'clientKey': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestMongodbCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/MongodbCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'LdapCredentialValues': {
    'required': ['host'],
    'type': 'object',
    'properties': {
      'host': {
        'type': 'string'
      },
      'port': {
        'type': 'integer',
        'format': 'int32'
      },
      'baseDn': {
        'type': 'string'
      },
      'userDn': {
        'type': 'string'
      },
      'password': {
        'type': 'string'
      },
      'certificate': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestLdapCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/LdapCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'KafkaCredentialValues': {
    'required': ['bootstrapServers', 'topic'],
    'type': 'object',
    'properties': {
      'bootstrapServers': {
        'type': 'string'
      },
      'topic': {
        'type': 'string'
      },
      'groupId': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestKafkaCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/KafkaCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'JwtCredentialValues': {
    'required': ['keyType', 'privateKey'],
    'type': 'object',
    'properties': {
      'payload': {
        'type': 'object',
        'additionalProperties': {
          'type': 'object'
        }
      },
      'privateKey': {
        'type': 'string'
      },
      'keyType': {
        'type': 'string'
      },
      'signatureAlgorithm': {
        'type': 'string',
        'enum': ['NONE', 'HS256', 'HS384', 'HS512', 'RS256', 'RS384', 'RS512', 'ES256', 'ES384', 'ES512', 'PS256', 'PS384', 'PS512']
      }
    }
  },
  'ProcessCredentialRequestJwtCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/JwtCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'JmsCredentialValues': {
    'required': ['destinationName', 'destinationType', 'uri'],
    'type': 'object',
    'properties': {
      'uri': {
        'type': 'string'
      },
      'username': {
        'type': 'string'
      },
      'password': {
        'type': 'string'
      },
      'destinationType': {
        'type': 'string',
        'enum': ['TOPIC', 'QUEUE']
      },
      'destinationName': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestJmsCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/JmsCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'JdbcAdminOptions': {
    'type': 'object',
    'properties': {
      'queryConsole': {
        'type': 'boolean'
      }
    }
  },
  'JdbcCredentialValues': {
    'required': ['connectionTimeout', 'maxPoolSize', 'password', 'url', 'username'],
    'type': 'object',
    'properties': {
      'url': {
        'type': 'string'
      },
      'username': {
        'type': 'string'
      },
      'password': {
        'type': 'string'
      },
      'maxPoolSize': {
        'type': 'integer',
        'format': 'int32'
      },
      'connectionTimeout': {
        'minimum': 0,
        'type': 'integer',
        'format': 'int32'
      }
    }
  },
  'ProcessCredentialRequestJdbcCredentialValuesJdbcAdminOptions': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/JdbcCredentialValues'
      },
      'adminOptions': {
        '$ref': '#/definitions/JdbcAdminOptions'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'ImapCredentialValues': {
    'required': ['host', 'password', 'port', 'username'],
    'type': 'object',
    'properties': {
      'host': {
        'type': 'string'
      },
      'port': {
        'type': 'integer',
        'format': 'int32'
      },
      'username': {
        'type': 'string'
      },
      'password': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestImapCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/ImapCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'FtpCredentialValues': {
    'required': ['host', 'username'],
    'type': 'object',
    'properties': {
      'host': {
        'type': 'string'
      },
      'username': {
        'type': 'string'
      },
      'port': {
        'type': 'integer',
        'format': 'int32'
      },
      'password': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestFtpCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/FtpCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'FlowyCredentialValues': {
    'required': ['apiKey', 'url', 'username'],
    'type': 'object',
    'properties': {
      'url': {
        'type': 'string'
      },
      'username': {
        'type': 'string'
      },
      'apiKey': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestFlowyCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/FlowyCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'AwsCredentialValues': {
    'required': ['accessKeyId', 'region', 'secretKey'],
    'type': 'object',
    'properties': {
      'accessKeyId': {
        'type': 'string'
      },
      'secretKey': {
        'type': 'string'
      },
      'region': {
        'type': 'string'
      },
      's3Bucket': {
        'type': 'string'
      },
      'host': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialRequestAwsCredentialValuesVoid': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'values'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        '$ref': '#/definitions/AwsCredentialValues'
      },
      'adminOptions': {
        'type': 'object'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldToEncrypt'
        }
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'PluginRequest': {
    'required': ['actions', 'className', 'name', 'roles', 'status'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'className': {
        'type': 'string'
      },
      'actions': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      }
    }
  },
  'FlowyId': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'UpdatePasswordRequest': {
    'required': ['newPassword', 'oldPassword'],
    'type': 'object',
    'properties': {
      'oldPassword': {
        'type': 'string'
      },
      'newPassword': {
        'type': 'string'
      }
    }
  },
  'ModuleDescription': {
    'required': ['description', 'language'],
    'type': 'object',
    'properties': {
      'description': {
        'type': 'string'
      },
      'language': {
        'type': 'string'
      }
    }
  },
  'ModuleRequest': {
    'required': ['name', 'resources', 'roles'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'resources': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceId'
        }
      },
      'descriptions': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ModuleDescription'
        }
      },
      'loadCredentialId': {
        'type': 'integer',
        'format': 'int64'
      },
      'loadDirection': {
        'type': 'string',
        'enum': ['UPLOAD', 'DOWNLOAD']
      }
    }
  },
  'ResourceId': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'type': {
        'type': 'string',
        'enum': ['PROCESS_CREDENTIAL', 'TRIGGER_CRON', 'TRIGGER_REST', 'TRIGGER_MESSAGING', 'TRIGGER_EVENT_HANDLER', 'TEXT_TEMPLATE', 'GLOBAL_TRANSLATION', 'SETTING', 'MODULE', 'VALIDATION_RULE', 'PLUGIN', 'LIBRARY', 'ENTITY', 'PROCESS']
      }
    }
  },
  'ModuleFull': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'resources': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceIdName'
        }
      },
      'descriptions': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ModuleDescription'
        }
      },
      'loadCredentialId': {
        'type': 'integer',
        'format': 'int64'
      },
      'loadDirection': {
        'type': 'string',
        'enum': ['UPLOAD', 'DOWNLOAD']
      },
      'remoteId': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'ResourceIdName': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['PROCESS_CREDENTIAL', 'TRIGGER_CRON', 'TRIGGER_REST', 'TRIGGER_MESSAGING', 'TRIGGER_EVENT_HANDLER', 'TEXT_TEMPLATE', 'GLOBAL_TRANSLATION', 'SETTING', 'MODULE', 'VALIDATION_RULE', 'PLUGIN', 'LIBRARY', 'ENTITY', 'PROCESS']
      }
    }
  },
  'LibraryRequest': {
    'required': ['isGdprRelevant', 'name', 'roles', 'status', 'type'],
    'type': 'object',
    'properties': {
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'name': {
        'maxLength': 250,
        'minLength': 2,
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'type': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'CredentialValues': {
    'type': 'object',
    'properties': {
      'syncDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'inactiveTtlInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'FtpValues': {
    'type': 'object',
    'properties': {
      'inactiveTtlInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'GlobalSettingRequestProcessingGlobalSettingValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/ProcessingGlobalSettingValues'
      }
    }
  },
  'GroovyScriptValues': {
    'type': 'object',
    'properties': {
      'cleanUpDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'inactiveTtlInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'InternalJobValues': {
    'type': 'object',
    'properties': {
      'heartbeatInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'timeoutInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'LibraryValues': {
    'type': 'object',
    'properties': {
      'syncDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'PluginValues': {
    'type': 'object',
    'properties': {
      'syncDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'ProcessExecutionValues': {
    'type': 'object',
    'properties': {
      'fetchEventsDelayInMSec': {
        'type': 'integer',
        'format': 'int64'
      },
      'cleanUpLogsDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'cleanUpErrorsDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'cleanUpBatchSize': {
        'minimum': 1000,
        'type': 'integer',
        'format': 'int32'
      },
      'heartbeatInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'fixExecutionStateDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'executeProcessStepChildEventDelayInMSec': {
        'type': 'integer',
        'format': 'int64'
      },
      'fetchedTtlInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'ProcessingGlobalSettingValues': {
    'required': ['credentials', 'ftp', 'groovyScript', 'internalJob', 'library', 'plugin', 'processExecution', 'restTemplate', 'storage', 'triggerCron', 'triggerEventHandler', 'triggerMessaging', 'triggerRest'],
    'type': 'object',
    'properties': {
      'instanceHeartbeatInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'userRegisterTokenTtlInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'syncSettingsDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'systemSchedulerPoolSize': {
        'minimum': 1,
        'type': 'integer',
        'format': 'int32'
      },
      'security': {
        '$ref': '#/definitions/Security'
      },
      'internalJob': {
        '$ref': '#/definitions/InternalJobValues'
      },
      'maxProcessingDurationInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int32'
      },
      'triggerCron': {
        '$ref': '#/definitions/TriggerCronValues'
      },
      'triggerRest': {
        '$ref': '#/definitions/TriggerRestValues'
      },
      'triggerMessaging': {
        '$ref': '#/definitions/TriggerMessagingValues'
      },
      'triggerEventHandler': {
        '$ref': '#/definitions/TriggerEventHandlerValues'
      },
      'plugin': {
        '$ref': '#/definitions/PluginValues'
      },
      'processExecution': {
        '$ref': '#/definitions/ProcessExecutionValues'
      },
      'storage': {
        '$ref': '#/definitions/StorageValues'
      },
      'library': {
        '$ref': '#/definitions/LibraryValues'
      },
      'restTemplate': {
        '$ref': '#/definitions/RestTemplateValues'
      },
      'groovyScript': {
        '$ref': '#/definitions/GroovyScriptValues'
      },
      'credentials': {
        '$ref': '#/definitions/CredentialValues'
      },
      'ftp': {
        '$ref': '#/definitions/FtpValues'
      }
    }
  },
  'RestTemplateValues': {
    'type': 'object',
    'properties': {
      'cleanUpDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'inactiveTtlInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'Security': {
    'type': 'object',
    'properties': {
      'prometheusMetricsWhitelist': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      }
    }
  },
  'StorageValues': {
    'type': 'object',
    'properties': {
      'cleanUpDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'TriggerCronValues': {
    'type': 'object',
    'properties': {
      'syncDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'TriggerEventHandlerValues': {
    'type': 'object',
    'properties': {
      'syncDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'handleDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'eventsCount': {
        'type': 'integer',
        'format': 'int32'
      }
    }
  },
  'TriggerMessagingValues': {
    'type': 'object',
    'properties': {
      'syncDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'TriggerRestValues': {
    'type': 'object',
    'properties': {
      'waitEventTimeInMSec': {
        'type': 'integer',
        'format': 'int64'
      },
      'syncDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'GlobalSettingFull': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'type': {
        'type': 'string',
        'enum': ['ADMIN', 'PROCESSING', 'FRONTEND']
      },
      'values': {
        'type': 'object',
        'additionalProperties': {
          'type': 'object'
        }
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      }
    }
  },
  'FrontendGlobalSettingValues': {
    'required': ['baseUrlRest', 'defaultLocale', 'setPasswordUrl'],
    'type': 'object',
    'properties': {
      'baseUrlRest': {
        'type': 'string'
      },
      'logServerUrl': {
        'type': 'string'
      },
      'metricServerUrl': {
        'type': 'string'
      },
      'visualisationServerUrl': {
        'type': 'string'
      },
      'setPasswordUrl': {
        'type': 'string'
      },
      'defaultLocale': {
        'maxLength': 2,
        'minLength': 2,
        'type': 'string'
      },
      'environmentName': {
        'type': 'string'
      }
    }
  },
  'GlobalSettingRequestFrontendGlobalSettingValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/FrontendGlobalSettingValues'
      }
    }
  },
  'AdminGlobalSettingValues': {
    'required': ['captcha', 'history', 'internalJob', 'moduleVersion'],
    'type': 'object',
    'properties': {
      'instanceHeartbeatInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'userRegisterTokenTtlInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'syncSettingsDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'systemSchedulerPoolSize': {
        'minimum': 1,
        'type': 'integer',
        'format': 'int32'
      },
      'security': {
        '$ref': '#/definitions/Security'
      },
      'internalJob': {
        '$ref': '#/definitions/InternalJobValues'
      },
      'moduleVersion': {
        'type': 'string'
      },
      'captcha': {
        '$ref': '#/definitions/CaptchaValues'
      },
      'history': {
        '$ref': '#/definitions/HistoryValues'
      }
    }
  },
  'CaptchaValues': {
    'type': 'object',
    'properties': {
      'ttlInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'cleanDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'GlobalSettingRequestAdminGlobalSettingValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/AdminGlobalSettingValues'
      }
    }
  },
  'HistoryValues': {
    'type': 'object',
    'properties': {
      'ttlInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'cleanDelayInMSec': {
        'minimum': 500,
        'type': 'integer',
        'format': 'int64'
      },
      'cleanEnabled': {
        'type': 'boolean'
      }
    }
  },
  'EntityFieldRequest': {
    'required': ['dataType', 'name'],
    'type': 'object',
    'properties': {
      'name': {
        'maxLength': 30,
        'minLength': 0,
        'pattern': '^[\\w]+$',
        'type': 'string'
      },
      'dataType': {
        'type': 'string',
        'enum': ['VARCHAR', 'TIMESTAMP', 'BIGINT', 'INTEGER', 'DOUBLE', 'FLOAT', 'JSON', 'BOOLEAN']
      },
      'searchType': {
        'type': 'string',
        'enum': ['NO', 'OPTIONAL', 'REQUIRED']
      },
      'relation': {
        '$ref': '#/definitions/RelationRequest'
      },
      'defaultValue': {
        'type': 'string'
      },
      'matchingPattern': {
        'type': 'string',
        'enum': ['EXACT', 'BEGINNING', 'END', 'EVERYWHERE', 'BETWEEN']
      },
      'primaryKey': {
        'type': 'boolean'
      },
      'sortable': {
        'type': 'boolean'
      },
      'unique': {
        'type': 'boolean'
      },
      'required': {
        'type': 'boolean'
      }
    }
  },
  'EntityRequest': {
    'required': ['credentialId', 'fields', 'isGdprRelevant', 'name', 'roles', 'status'],
    'type': 'object',
    'properties': {
      'name': {
        'maxLength': 64,
        'minLength': 2,
        'pattern': '^\\w+$',
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'fields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/EntityFieldRequest'
        }
      },
      'credentialId': {
        'type': 'integer',
        'format': 'int64'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'systemPermissionRoles': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          '$ref': '#/definitions/SystemPermissionRole'
        }
      }
    }
  },
  'RelationRequest': {
    'required': ['type'],
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'type': {
        'type': 'string',
        'enum': ['ONE_TO_ONE', 'ONE_TO_MANY', 'MANY_TO_ONE', 'MANY_TO_MANY']
      },
      'entity': {
        '$ref': '#/definitions/EntityRequest'
      }
    }
  },
  'SystemPermissionRole': {
    'required': ['permissionType', 'roleId'],
    'type': 'object',
    'properties': {
      'roleId': {
        'type': 'integer',
        'format': 'int64'
      },
      'permissionType': {
        'type': 'string',
        'enum': ['READ', 'CREATE', 'UPDATE', 'DELETE']
      }
    }
  },
  'ResourceStatus': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      }
    }
  },
  'EntityPersistenceResult': {
    'type': 'object',
    'properties': {
      'statements': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'entities': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/PersistenceEntity'
        }
      }
    }
  },
  'PersistenceDifferences': {
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'entity': {
        '$ref': '#/definitions/PersistenceFlowyEntityField'
      },
      'persistence': {
        '$ref': '#/definitions/PersistenceFlowyEntityField'
      }
    }
  },
  'PersistenceEntity': {
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'version': {
        '$ref': '#/definitions/PersistenceVersion'
      },
      'fields': {
        '$ref': '#/definitions/PersistenceFields'
      }
    }
  },
  'PersistenceFields': {
    'type': 'object',
    'properties': {
      'entity': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/PersistenceFlowyEntityFieldWithName'
        }
      },
      'persistence': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/PersistenceFlowyEntityFieldWithName'
        }
      },
      'differences': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/PersistenceDifferences'
        }
      },
      'common': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/PersistenceFlowyEntityFieldWithName'
        }
      }
    }
  },
  'PersistenceFlowyEntityField': {
    'type': 'object',
    'properties': {
      'dataType': {
        'type': 'string',
        'enum': ['VARCHAR', 'TIMESTAMP', 'BIGINT', 'INTEGER', 'DOUBLE', 'FLOAT', 'JSON', 'BOOLEAN']
      },
      'required': {
        'type': 'boolean'
      },
      'searchType': {
        'type': 'string',
        'enum': ['NO', 'OPTIONAL', 'REQUIRED']
      },
      'unique': {
        'type': 'boolean'
      },
      'primaryKey': {
        'type': 'boolean'
      },
      'relation': {
        '$ref': '#/definitions/PersistenceRelation'
      },
      'defaultValue': {
        'type': 'string'
      },
      'sortable': {
        'type': 'boolean'
      }
    }
  },
  'PersistenceFlowyEntityFieldWithName': {
    'type': 'object',
    'properties': {
      'dataType': {
        'type': 'string',
        'enum': ['VARCHAR', 'TIMESTAMP', 'BIGINT', 'INTEGER', 'DOUBLE', 'FLOAT', 'JSON', 'BOOLEAN']
      },
      'required': {
        'type': 'boolean'
      },
      'searchType': {
        'type': 'string',
        'enum': ['NO', 'OPTIONAL', 'REQUIRED']
      },
      'unique': {
        'type': 'boolean'
      },
      'primaryKey': {
        'type': 'boolean'
      },
      'relation': {
        '$ref': '#/definitions/PersistenceRelation'
      },
      'defaultValue': {
        'type': 'string'
      },
      'sortable': {
        'type': 'boolean'
      },
      'name': {
        'type': 'string'
      }
    }
  },
  'PersistenceRelation': {
    'type': 'object',
    'properties': {
      'entityName': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['ONE_TO_ONE', 'ONE_TO_MANY', 'MANY_TO_ONE', 'MANY_TO_MANY']
      }
    }
  },
  'PersistenceVersion': {
    'type': 'object',
    'properties': {
      'entity': {
        'type': 'integer',
        'format': 'int32'
      },
      'persistence': {
        'type': 'integer',
        'format': 'int32'
      }
    }
  },
  'OtpCodeRequest': {
    'required': ['otpCode'],
    'type': 'object',
    'properties': {
      'otpCode': {
        'pattern': '^\\d{6}$',
        'type': 'string'
      }
    }
  },
  'UserSettingsRequest': {
    'required': ['settings'],
    'type': 'object',
    'properties': {
      'settings': {
        'type': 'object',
        'additionalProperties': {
          'type': 'object'
        }
      }
    }
  },
  'CreatePdfRequest': {
    'required': ['templateBody', 'type'],
    'type': 'object',
    'properties': {
      'templateBody': {
        'type': 'string'
      },
      'variables': {
        'type': 'object',
        'additionalProperties': {
          'type': 'object'
        }
      },
      'type': {
        'type': 'string',
        'enum': ['THYMELEAF', 'MUSTACHE', 'VELOCITY', 'HANDLEBARS', 'MARKDOWN']
      }
    }
  },
  'SetUserPasswordRequest': {
    'required': ['password', 'registerToken'],
    'type': 'object',
    'properties': {
      'registerToken': {
        'type': 'string'
      },
      'password': {
        'type': 'string'
      }
    }
  },
  'RegexTestRequest': {
    'required': ['regex', 'value'],
    'type': 'object',
    'properties': {
      'regex': {
        'type': 'string'
      },
      'value': {
        'type': 'string'
      }
    }
  },
  'RegexTest': {
    'type': 'object',
    'properties': {
      'matches': {
        'type': 'boolean'
      }
    }
  },
  'ProcessCredentialTestRequestTwilioCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/TwilioCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTest': {
    'type': 'object',
    'properties': {
      'isValid': {
        'type': 'boolean'
      },
      'error': {
        'type': 'string'
      }
    }
  },
  'ProcessCredentialTestRequestSshCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/SshCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestSshKeyCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/SshKeyCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestSmtpCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/SmtpCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestSlackCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/SlackCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestSftpCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/SftpCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestRabbitmqCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/RabbitmqCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestPaymentSensePacCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/PaymentSensePacCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestPaymentSenseConnectECredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/PaymentSenseConnectECredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestOutlookCalendarCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/OutlookCalendarCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestOpenAICredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/OpenAICredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestMqttCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/MqttCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestMongodbCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/MongodbCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestLdapCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/LdapCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestKafkaCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/KafkaCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestJwtCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/JwtCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestJmsCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/JmsCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestJdbcCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/JdbcCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestImapCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/ImapCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestFtpCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/FtpCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestFlowyCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/FlowyCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ProcessCredentialTestRequestAwsCredentialValues': {
    'required': ['values'],
    'type': 'object',
    'properties': {
      'values': {
        '$ref': '#/definitions/AwsCredentialValues'
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      }
    }
  },
  'ExecuteJdbcScriptRequest': {
    'required': ['script'],
    'type': 'object',
    'properties': {
      'script': {
        'type': 'string'
      }
    }
  },
  'JdbcExecuteScriptResult': {
    'type': 'object',
    'properties': {
      'affectedRows': {
        'type': 'integer',
        'format': 'int32'
      },
      'result': {
        'type': 'object'
      },
      'isSuccess': {
        'type': 'boolean'
      },
      'error': {
        'type': 'string'
      }
    }
  },
  'ArtifactInformation': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'filename': {
        'type': 'string'
      },
      'size': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'ResourceImportResult': {
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['PROCESS_CREDENTIAL', 'TRIGGER_CRON', 'TRIGGER_REST', 'TRIGGER_MESSAGING', 'TRIGGER_EVENT_HANDLER', 'TEXT_TEMPLATE', 'GLOBAL_TRANSLATION', 'SETTING', 'MODULE', 'VALIDATION_RULE', 'PLUGIN', 'LIBRARY', 'ENTITY', 'PROCESS']
      },
      'isExist': {
        'type': 'boolean'
      },
      'hasPermission': {
        'type': 'boolean'
      },
      'conflicts': {
        'type': 'array',
        'items': {
          'type': 'array',
          'items': {
            'type': 'string'
          }
        }
      },
      'missedRequiredResources': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceName'
        }
      }
    }
  },
  'ResourceName': {
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['PROCESS_CREDENTIAL', 'TRIGGER_CRON', 'TRIGGER_REST', 'TRIGGER_MESSAGING', 'TRIGGER_EVENT_HANDLER', 'TEXT_TEMPLATE', 'GLOBAL_TRANSLATION', 'SETTING', 'MODULE', 'VALIDATION_RULE', 'PLUGIN', 'LIBRARY', 'ENTITY', 'PROCESS']
      }
    }
  },
  'LoginRequest': {
    'required': ['email', 'password'],
    'type': 'object',
    'properties': {
      'email': {
        'type': 'string'
      },
      'password': {
        'type': 'string'
      },
      'otpCode': {
        'pattern': '^\\d{6}$',
        'type': 'string'
      }
    }
  },
  'UserAuthToken': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'email': {
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Role'
        }
      },
      'authToken': {
        'type': 'string'
      },
      'twoFactorAuth': {
        'type': 'boolean'
      },
      'twoFactorAuthEnabled': {
        'type': 'boolean'
      }
    }
  },
  'HubReviewCreateRequest': {
    'required': ['body', 'credentialId', 'remoteModuleId', 'title'],
    'type': 'object',
    'properties': {
      'credentialId': {
        'type': 'integer',
        'format': 'int64'
      },
      'remoteModuleId': {
        'type': 'integer',
        'format': 'int64'
      },
      'rating': {
        'maximum': 5,
        'minimum': 1,
        'type': 'integer',
        'format': 'int32'
      },
      'title': {
        'type': 'string'
      },
      'body': {
        'type': 'string'
      }
    }
  },
  'ForgotPasswordRequest': {
    'required': ['captchaAnswer', 'captchaKey', 'email'],
    'type': 'object',
    'properties': {
      'email': {
        'type': 'string'
      },
      'captchaKey': {
        'type': 'string'
      },
      'captchaAnswer': {
        'type': 'string'
      }
    }
  },
  'EntityResourcesRequest': {
    'required': ['resources'],
    'type': 'object',
    'properties': {
      'resources': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceAction'
        }
      }
    }
  },
  'ResourceAction': {
    'required': ['actionType', 'resourceType'],
    'type': 'object',
    'properties': {
      'resourceType': {
        'type': 'string',
        'enum': ['VALIDATION_RULE', 'PROCESS', 'TRIGGER_REST', 'TRIGGER_MESSAGING']
      },
      'actionType': {
        'type': 'string',
        'enum': ['CREATE', 'UPDATE', 'DELETE', 'SEARCH', 'FIND_BY_ID']
      }
    }
  },
  'BaseResource': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'isSystem': {
        'type': 'boolean'
      }
    }
  },
  'ResourceDiff': {
    'type': 'object',
    'properties': {
      'resourceType': {
        'type': 'string',
        'enum': ['VALIDATION_RULE', 'PROCESS', 'TRIGGER_REST', 'TRIGGER_MESSAGING']
      },
      'generated': {
        '$ref': '#/definitions/BaseResource'
      },
      'existing': {
        '$ref': '#/definitions/BaseResource'
      }
    }
  },
  'EntityFromPersistenceRequest': {
    'required': ['credentialId', 'persistenceName', 'roles'],
    'type': 'object',
    'properties': {
      'credentialId': {
        'type': 'integer',
        'format': 'int64'
      },
      'persistenceName': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      }
    }
  },
  'MetaPageResponse': {
    'type': 'object',
    'properties': {
      'pageNumber': {
        'type': 'integer',
        'format': 'int32'
      },
      'totalPages': {
        'type': 'integer',
        'format': 'int32'
      },
      'totalItems': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'PageResponseValidationRule': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ValidationRule'
        }
      }
    }
  },
  'PageResponseUser': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/User'
        }
      }
    }
  },
  'UserFull': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'email': {
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Role'
        }
      },
      'lastLogin': {
        'type': 'string',
        'format': 'date-time'
      },
      'allowedLoginAttempts': {
        'type': 'integer',
        'format': 'int32'
      },
      'allowedApiAttempts': {
        'type': 'integer',
        'format': 'int32'
      },
      'failedLoginAttempts': {
        'type': 'integer',
        'format': 'int32'
      },
      'failedApiAttempts': {
        'type': 'integer',
        'format': 'int32'
      },
      'twoFactorAuthEnabled': {
        'type': 'boolean'
      }
    }
  },
  'UserNameProj': {
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      }
    }
  },
  'QRResponse': {
    'type': 'object',
    'properties': {
      'qrCode': {
        'type': 'string'
      }
    }
  },
  'PageResponseTriggerRest': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerRest'
        }
      }
    }
  },
  'TriggerRestExists': {
    'type': 'object',
    'properties': {
      'isExist': {
        'type': 'boolean'
      },
      'triggerId': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'PageResponseTriggerMessaging': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerMessaging'
        }
      }
    }
  },
  'PageResponseTriggerEventHandler': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerEventHandler'
        }
      }
    }
  },
  'PageResponseTriggerCron': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerCron'
        }
      }
    }
  },
  'EventIdResponse': {
    'type': 'object',
    'properties': {
      'eventId': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'PageResponseTextTranslation': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TextTranslation'
        }
      }
    }
  },
  'PageResponseTextTemplate': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TextTemplate'
        }
      }
    }
  },
  'TextTemplate': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'isSystem': {
        'type': 'boolean'
      }
    }
  },
  'PageResponseTelemetry': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Telemetry'
        }
      }
    }
  },
  'Telemetry': {
    'type': 'object',
    'properties': {
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'processName': {
        'type': 'string'
      },
      'count': {
        'type': 'integer',
        'format': 'int64'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      }
    }
  },
  'PageResponseStorage': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Storage'
        }
      }
    }
  },
  'StorageFull': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'processName': {
        'type': 'string'
      },
      'key': {
        'type': 'string'
      },
      'expireOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'value': {
        'type': 'object'
      }
    }
  },
  'PageResponseSetting': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Setting'
        }
      }
    }
  },
  'PageResponseRole': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Role'
        }
      }
    }
  },
  'FlowyProcess': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'isSystem': {
        'type': 'boolean'
      }
    }
  },
  'PageResponseFlowyProcess': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/FlowyProcess'
        }
      }
    }
  },
  'BaseTrigger': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'type': {
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      }
    }
  },
  'PageResponseBaseTrigger': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/BaseTrigger'
        }
      }
    }
  },
  'EventStatusCount': {
    'type': 'object',
    'properties': {
      'count': {
        'type': 'integer',
        'format': 'int64'
      },
      'status': {
        'type': 'string',
        'enum': ['NEW', 'FETCHED', 'IN_PROGRESS', 'ON_HOLD', 'INTERRUPT_REQUEST', 'FAILED', 'SUCCESSFUL', 'PROCESS_INACTIVE', 'TIMEOUT', 'INTERRUPT_ACKNOWLEDGED']
      }
    }
  },
  'ProcessStatistics': {
    'type': 'object',
    'properties': {
      'last15MinutesCount': {
        'type': 'integer',
        'format': 'int64'
      },
      'last60MinutesCount': {
        'type': 'integer',
        'format': 'int64'
      },
      'last24HoursCount': {
        'type': 'integer',
        'format': 'int64'
      },
      'todayCount': {
        'type': 'integer',
        'format': 'int64'
      },
      'eventStatuses': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/EventStatusCount'
        }
      }
    }
  },
  'PageResponseProcessStepLog': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ProcessStepLog'
        }
      }
    }
  },
  'ProcessStepLog': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'eventId': {
        'type': 'integer',
        'format': 'int64'
      },
      'childEventId': {
        'type': 'integer',
        'format': 'int64'
      },
      'instanceId': {
        'type': 'string'
      },
      'stepId': {
        'type': 'integer',
        'format': 'int32'
      },
      'status': {
        'type': 'string',
        'enum': ['FAILED', 'SUCCESSFUL', 'CAUGHT', 'IN_PROGRESS']
      },
      'stepType': {
        'type': 'string',
        'enum': ['MONGODB', 'JDBC', 'MESSAGING', 'FOREACH', 'SWITCH', 'WHILE', 'TRY_CATCH', 'JS', 'GROOVY', 'PYTHON', 'UNSET_VARIABLES', 'EMAIL', 'SLACK', 'TWILIO', 'PAYMENT_SENSE_PAC', 'PAYMENT_SENSE_CONNECT_E', 'EXECUTE_PROCESS', 'PROCESS_SETTING', 'USER', 'PLUGIN', 'CREDENTIAL', 'REST', 'JWT', 'SECURITY', 'S3', 'IMAP', 'PDF', 'CSV', 'IMAGE', 'UUID', 'EXECUTE_EXTERNAL_COMMAND', 'QUERY_BUILDER', 'ZIP', 'LOG', 'ENCODER', 'STORAGE', 'FORMATTING', 'XSLT', 'GRAPHQL', 'ESC_CHARS', 'FTP', 'XML', 'OCR', 'QR', 'CHAT_GPT', 'LDAP', 'SSH', 'XLS', 'YAML', 'I_CALENDAR', 'OUTLOOK_CALENDAR']
      },
      'finishedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'isExistException': {
        'type': 'boolean'
      },
      'isExistDebugLog': {
        'type': 'boolean'
      }
    }
  },
  'ProcessStepLogException': {
    'type': 'object',
    'properties': {
      'message': {
        'type': 'string'
      },
      'stackTrace': {
        'type': 'string'
      }
    }
  },
  'ProcessStepLogDebugLog': {
    'type': 'object',
    'properties': {
      'debugLog': {
        'type': 'string'
      }
    }
  },
  'ProcessStepLogCache': {
    'type': 'object',
    'properties': {
      'cache': {
        'type': 'object'
      }
    }
  },
  'PageResponseProcessStepFailure': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ProcessStepFailure'
        }
      }
    }
  },
  'ProcessStepFailure': {
    'type': 'object',
    'properties': {
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'exception': {
        'type': 'string'
      },
      'count': {
        'type': 'integer',
        'format': 'int64'
      },
      'firstOccurrence': {
        'type': 'string',
        'format': 'date-time'
      },
      'lastOccurrence': {
        'type': 'string',
        'format': 'date-time'
      }
    }
  },
  'PageResponseProcessCredential': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ProcessCredential'
        }
      }
    }
  },
  'PageResponsePlugin': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Plugin'
        }
      }
    }
  },
  'Plugin': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      }
    }
  },
  'PluginRoles': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'className': {
        'type': 'string'
      },
      'actions': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'filename': {
        'type': 'string'
      },
      'size': {
        'type': 'integer',
        'format': 'int32'
      }
    }
  },
  'FlowyModule': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'resources': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceId'
        }
      },
      'descriptions': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ModuleDescription'
        }
      },
      'loadCredentialId': {
        'type': 'integer',
        'format': 'int64'
      },
      'loadDirection': {
        'type': 'string',
        'enum': ['UPLOAD', 'DOWNLOAD']
      },
      'remoteId': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'PageResponseFlowyModule': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/FlowyModule'
        }
      }
    }
  },
  'EntityExport': {
    'required': ['fields', 'name', 'roles', 'status'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'fields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/FlowyEntityField'
        }
      },
      'relations': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RelationExport'
        }
      },
      'credentialName': {
        'type': 'string'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'version': {
        'type': 'integer',
        'format': 'int32'
      },
      'systemPermissionRoleExports': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          '$ref': '#/definitions/SystemPermissionRoleExport'
        }
      }
    }
  },
  'FlowyEntityField': {
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'dataType': {
        'type': 'string',
        'enum': ['VARCHAR', 'TIMESTAMP', 'BIGINT', 'INTEGER', 'DOUBLE', 'FLOAT', 'JSON', 'BOOLEAN']
      },
      'searchType': {
        'type': 'string',
        'enum': ['NO', 'OPTIONAL', 'REQUIRED']
      },
      'defaultValue': {
        'type': 'string'
      },
      'matchingPattern': {
        'type': 'string',
        'enum': ['EXACT', 'BEGINNING', 'END', 'EVERYWHERE', 'BETWEEN']
      },
      'primaryKey': {
        'type': 'boolean'
      },
      'sortable': {
        'type': 'boolean'
      },
      'unique': {
        'type': 'boolean'
      },
      'required': {
        'type': 'boolean'
      }
    }
  },
  'LibraryExport': {
    'required': ['name', 'roles', 'status', 'type'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'artifact': {
        'type': 'string'
      },
      'filename': {
        'type': 'string'
      },
      'size': {
        'type': 'integer',
        'format': 'int64'
      },
      'type': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      },
      'isGdprRelevant': {
        'type': 'boolean'
      }
    }
  },
  'ModuleExport': {
    'required': ['name', 'resources', 'roles'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'version': {
        'type': 'string'
      },
      'resources': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceName'
        }
      },
      'descriptions': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ModuleDescription'
        }
      },
      'loadCredentialName': {
        'type': 'string'
      },
      'loadDirection': {
        'type': 'string',
        'enum': ['UPLOAD', 'DOWNLOAD']
      },
      'remoteId': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'ModuleExportResult': {
    'required': ['name', 'resources', 'version'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'version': {
        'type': 'string'
      },
      'descriptions': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ModuleDescription'
        }
      },
      'resources': {
        '$ref': '#/definitions/ResourcesExport'
      },
      'notExported': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceId'
        }
      }
    }
  },
  'PluginExport': {
    'required': ['className', 'name', 'roles', 'status'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'jar': {
        'type': 'string'
      },
      'className': {
        'type': 'string'
      },
      'actions': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'filename': {
        'type': 'string'
      },
      'size': {
        'type': 'integer',
        'format': 'int32'
      }
    }
  },
  'ProcessCredentialExport': {
    'required': ['name', 'roles', 'status', 'type'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'type': {
        'type': 'string',
        'enum': ['JDBC', 'MONGODB', 'TWILIO', 'SLACK', 'SMTP', 'IMAP', 'REST', 'REST_BASIC', 'REST_BEARER', 'PAYMENT_SENSE_PAC', 'PAYMENT_SENSE_CONNECT_E', 'FLOWY', 'PLUGIN', 'AWS', 'SECURITY', 'SCRIPT', 'JWT', 'OPEN_AI', 'LDAP', 'KAFKA', 'JMS', 'RABBITMQ', 'MQTT', 'FTP', 'SFTP', 'SSH', 'SSH_KEY', 'OUTLOOK_CALENDAR']
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'isSystem': {
        'type': 'boolean'
      },
      'adminOptions': {
        'type': 'object',
        'additionalProperties': {
          'type': 'object'
        }
      },
      'encryptedFields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/CredentialFieldEncryptionStatus'
        }
      },
      'values': {
        'type': 'object',
        'additionalProperties': {
          'type': 'object'
        }
      }
    }
  },
  'ProcessExport': {
    'required': ['isCacheStepLogsEnabled', 'isDebugLogEnabled', 'isStepLogsEnabled', 'name', 'roles', 'status'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'overallSimultaneousExecutions': {
        'type': 'integer',
        'format': 'int32'
      },
      'simultaneousExecutionsPerInstance': {
        'type': 'integer',
        'format': 'int32'
      },
      'maxProcessingDurationInMSec': {
        'type': 'integer',
        'format': 'int32'
      },
      'logsTtlInMSec': {
        'type': 'integer',
        'format': 'int64'
      },
      'errorsTtlInMSec': {
        'type': 'integer',
        'format': 'int64'
      },
      'steps': {
        '$ref': '#/definitions/ProcessSteps'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'isStepLogsEnabled': {
        'type': 'boolean'
      },
      'isCacheStepLogsEnabled': {
        'type': 'boolean'
      },
      'isDebugLogEnabled': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'inputValidationRuleName': {
        'type': 'string'
      },
      'outputValidationRuleName': {
        'type': 'string'
      },
      'entityName': {
        'type': 'string'
      },
      'isSystem': {
        'type': 'boolean'
      }
    }
  },
  'RelationExport': {
    'type': 'object',
    'properties': {
      'field': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['ONE_TO_ONE', 'ONE_TO_MANY', 'MANY_TO_ONE', 'MANY_TO_MANY']
      },
      'entityName': {
        'type': 'string'
      }
    }
  },
  'ResourceRoleExport': {
    'type': 'object',
    'properties': {
      'role': {
        '$ref': '#/definitions/RoleExport'
      },
      'permissionType': {
        'type': 'string',
        'enum': ['VIEW', 'USE', 'EDIT']
      }
    }
  },
  'ResourcesExport': {
    'type': 'object',
    'properties': {
      'processes': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ProcessExport'
        }
      },
      'processCredentials': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ProcessCredentialExport'
        }
      },
      'cronTriggers': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerCronExport'
        }
      },
      'validationRules': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ValidationRuleExport'
        }
      },
      'restTriggers': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerRestExport'
        }
      },
      'textTemplates': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TextTemplateExport'
        }
      },
      'globalTranslations': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TextTranslationExport'
        }
      },
      'modules': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ModuleExport'
        }
      },
      'settings': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/SettingExport'
        }
      },
      'plugins': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/PluginExport'
        }
      },
      'libraries': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/LibraryExport'
        }
      },
      'messagingTriggers': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerMessagingExport'
        }
      },
      'eventHandlerTriggers': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerEventHandlerExport'
        }
      },
      'entities': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/EntityExport'
        }
      }
    }
  },
  'RoleExport': {
    'required': ['name'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      }
    }
  },
  'RuleFieldExport': {
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'isRequired': {
        'type': 'boolean'
      },
      'dataType': {
        'type': 'string',
        'enum': ['NUMBER', 'INTEGER', 'LONG', 'DOUBLE', 'BOOLEAN', 'STRING', 'TIMESTAMP', 'FILE_JSON', 'FILE_XML', 'ARRAY', 'OBJECT']
      },
      'genericSubType': {
        'type': 'string',
        'enum': ['NUMBER', 'INTEGER', 'DOUBLE', 'BOOLEAN', 'STRING', 'TIMESTAMP', 'OBJECT']
      },
      'validationRuleName': {
        'type': 'string'
      },
      'rules': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RuleValue'
        }
      }
    }
  },
  'SettingExport': {
    'required': ['name', 'roles', 'status', 'type'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'type': {
        'type': 'string',
        'enum': ['INSTANCE_PROCESSING', 'INSTANCE_ADMIN', 'PROCESS', 'PLUGIN']
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'values': {
        'type': 'object',
        'additionalProperties': {
          'type': 'object'
        }
      },
      'isSystem': {
        'type': 'boolean'
      }
    }
  },
  'SystemPermissionRoleExport': {
    'type': 'object',
    'properties': {
      'role': {
        '$ref': '#/definitions/RoleExport'
      },
      'permissionType': {
        'type': 'string',
        'enum': ['READ', 'CREATE', 'UPDATE', 'DELETE']
      }
    }
  },
  'TextTemplateExport': {
    'required': ['name', 'roles'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'body': {
        'type': 'string'
      },
      'engine': {
        'type': 'string',
        'enum': ['THYMELEAF', 'MUSTACHE', 'VELOCITY', 'HANDLEBARS', 'MARKDOWN']
      },
      'isSystem': {
        'type': 'boolean'
      },
      'translations': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TextTranslationExport'
        }
      },
      'exampleVariables': {
        'type': 'object',
        'additionalProperties': {
          'type': 'object'
        }
      }
    }
  },
  'TextTranslationExport': {
    'required': ['language', 'name', 'roles', 'text'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'language': {
        'type': 'string'
      },
      'text': {
        'type': 'string'
      }
    }
  },
  'TriggerCronExport': {
    'required': ['name', 'roles', 'status', 'triggerCondition'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'processName': {
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'priority': {
        'type': 'integer',
        'format': 'int32'
      },
      'instanceIds': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'triggerCondition': {
        'type': 'string'
      }
    }
  },
  'TriggerEventHandlerExport': {
    'required': ['listenStatus', 'listenType', 'name', 'roles', 'status'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'processName': {
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'priority': {
        'type': 'integer',
        'format': 'int32'
      },
      'instanceIds': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'listenType': {
        'type': 'string',
        'enum': ['REST', 'CRON', 'RUN_PROCESS', 'MESSAGING', 'EVENT_HANDLER', 'ALL']
      },
      'listenStatus': {
        'type': 'string',
        'enum': ['FAILED', 'TIMEOUT', 'ALL']
      },
      'listenProcessName': {
        'type': 'string'
      }
    }
  },
  'TriggerMessagingExport': {
    'required': ['name', 'roles', 'service', 'status'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'processName': {
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'priority': {
        'type': 'integer',
        'format': 'int32'
      },
      'instanceIds': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'service': {
        'type': 'string',
        'enum': ['KAFKA', 'JMS', 'RABBITMQ', 'AWS_SQS', 'MQTT']
      },
      'credentialName': {
        'type': 'string'
      },
      'entityName': {
        'type': 'string'
      }
    }
  },
  'TriggerRestExport': {
    'required': ['method', 'name', 'roles', 'status', 'url'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'processName': {
        'type': 'string'
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'comment': {
        'type': 'string'
      },
      'priority': {
        'type': 'integer',
        'format': 'int32'
      },
      'instanceIds': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'url': {
        'type': 'string'
      },
      'method': {
        'type': 'string',
        'enum': ['GET', 'POST', 'PUT', 'PATCH', 'DELETE']
      },
      'maxRequestSize': {
        'type': 'integer',
        'format': 'int32'
      },
      'basicAccessAuthentication': {
        'type': 'boolean'
      },
      'validationRules': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/TriggerValidationRuleExport'
        }
      },
      'entityName': {
        'type': 'string'
      },
      'isSystem': {
        'type': 'boolean'
      }
    }
  },
  'TriggerValidationRuleExport': {
    'required': ['name'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['QUERY_PARAMS', 'HEADERS', 'BODY', 'PATH_VARIABLES']
      }
    }
  },
  'ValidationRuleExport': {
    'required': ['name', 'roles'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRoleExport'
        }
      },
      'fields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RuleFieldExport'
        }
      },
      'includes': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'entityName': {
        'type': 'string'
      },
      'isSystem': {
        'type': 'boolean'
      }
    }
  },
  'Library': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'type': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      }
    }
  },
  'PageResponseLibrary': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Library'
        }
      }
    }
  },
  'LibraryRoles': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'type': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'size': {
        'type': 'integer',
        'format': 'int64'
      },
      'filename': {
        'type': 'string'
      }
    }
  },
  'InternalJob': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'type': {
        'type': 'string',
        'enum': ['SETTINGS_SYNC', 'PLUGINS_SYNC', 'CREDENTIALS_SYNC', 'CRON_TRIGGERS_SYNC', 'REST_TRIGGER_SYNC', 'MESSAGING_TRIGGER_SYNC', 'EVENT_HANDLER_TRIGGERS_SYNC', 'HANDLE_EVENTS', 'LIBRARIES_SYNC', 'CLEAN_UP', 'FIX_EXECUTION_STATE', 'PROCESS_EVENTS', 'INSTANCE_HEARTBEAT', 'CLEAN_CAPTCHA', 'CLEAN_UP_ERRORS', 'CLEAN_HISTORY', 'CLEAN_STORAGE', 'CLEAN_REST_TEMPLATES', 'CLEAN_GROOVY_SCRIPTS']
      },
      'status': {
        'type': 'string',
        'enum': ['IN_PROGRESS', 'FINISHED', 'FAILED']
      },
      'instanceId': {
        'type': 'string'
      },
      'heartbeat': {
        'type': 'string',
        'format': 'date-time'
      },
      'started': {
        'type': 'string',
        'format': 'date-time'
      },
      'finished': {
        'type': 'string',
        'format': 'date-time'
      }
    }
  },
  'PageResponseInternalJob': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/InternalJob'
        }
      }
    }
  },
  'Instance': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['PROCESSING', 'ADMIN']
      },
      'heartbeat': {
        'type': 'string',
        'format': 'date-time'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'ipAddress': {
        'type': 'string'
      }
    }
  },
  'PageResponseInstance': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Instance'
        }
      }
    }
  },
  'InstanceFull': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['PROCESSING', 'ADMIN']
      },
      'heartbeat': {
        'type': 'string',
        'format': 'date-time'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'ipAddress': {
        'type': 'string'
      },
      'threadsStats': {
        'type': 'object'
      },
      'lastStartTime': {
        'type': 'string',
        'format': 'date-time'
      },
      'availableMemory': {
        'type': 'integer',
        'format': 'int64'
      },
      'totalMemory': {
        'type': 'integer',
        'format': 'int64'
      },
      'systemCpuLoad': {
        'type': 'integer',
        'format': 'int32'
      }
    }
  },
  'History': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'userId': {
        'type': 'integer',
        'format': 'int64'
      },
      'action': {
        'type': 'string',
        'enum': ['CREATE', 'EDIT', 'DELETE']
      },
      'resourceType': {
        'type': 'string',
        'enum': ['PROCESS_CREDENTIAL', 'TRIGGER_CRON', 'TRIGGER_REST', 'TRIGGER_MESSAGING', 'TRIGGER_EVENT_HANDLER', 'TEXT_TEMPLATE', 'GLOBAL_TRANSLATION', 'SETTING', 'MODULE', 'VALIDATION_RULE', 'PLUGIN', 'LIBRARY', 'ENTITY', 'PROCESS']
      },
      'resource': {
        'type': 'object'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      }
    }
  },
  'PageResponseHistory': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/History'
        }
      }
    }
  },
  'HistoryFull': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'userId': {
        'type': 'integer',
        'format': 'int64'
      },
      'action': {
        'type': 'string',
        'enum': ['CREATE', 'EDIT', 'DELETE']
      },
      'resourceId': {
        'type': 'integer',
        'format': 'int64'
      },
      'resourceName': {
        'type': 'string'
      },
      'resourceType': {
        'type': 'string',
        'enum': ['PROCESS_CREDENTIAL', 'TRIGGER_CRON', 'TRIGGER_REST', 'TRIGGER_MESSAGING', 'TRIGGER_EVENT_HANDLER', 'TEXT_TEMPLATE', 'GLOBAL_TRANSLATION', 'SETTING', 'MODULE', 'VALIDATION_RULE', 'PLUGIN', 'LIBRARY', 'ENTITY', 'PROCESS']
      },
      'resource': {
        'type': 'object'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      }
    }
  },
  'HistoryDelete': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'userId': {
        'type': 'integer',
        'format': 'int64'
      },
      'resourceId': {
        'type': 'integer',
        'format': 'int64'
      },
      'resourceName': {
        'type': 'string'
      },
      'resourceType': {
        'type': 'string',
        'enum': ['PROCESS_CREDENTIAL', 'TRIGGER_CRON', 'TRIGGER_REST', 'TRIGGER_MESSAGING', 'TRIGGER_EVENT_HANDLER', 'TEXT_TEMPLATE', 'GLOBAL_TRANSLATION', 'SETTING', 'MODULE', 'VALIDATION_RULE', 'PLUGIN', 'LIBRARY', 'ENTITY', 'PROCESS']
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      }
    }
  },
  'PageResponseHistoryDelete': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/HistoryDelete'
        }
      }
    }
  },
  'GlobalSetting': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'type': {
        'type': 'string',
        'enum': ['ADMIN', 'PROCESSING', 'FRONTEND']
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      }
    }
  },
  'GlobalSettingBasic': {
    'type': 'object',
    'properties': {
      'baseUrlRest': {
        'type': 'string'
      },
      'defaultLocale': {
        'type': 'string'
      },
      'environmentName': {
        'type': 'string'
      }
    }
  },
  'IdNameProj': {
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'Event': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'instanceId': {
        'type': 'string'
      },
      'processingStartOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'threadId': {
        'type': 'integer',
        'format': 'int64'
      },
      'triggerId': {
        'type': 'integer',
        'format': 'int64'
      },
      'type': {
        'type': 'string',
        'enum': ['REST', 'CRON', 'RUN_PROCESS', 'MESSAGING', 'EVENT_HANDLER']
      },
      'status': {
        'type': 'string',
        'enum': ['NEW', 'FETCHED', 'IN_PROGRESS', 'ON_HOLD', 'INTERRUPT_REQUEST', 'FAILED', 'SUCCESSFUL', 'PROCESS_INACTIVE', 'TIMEOUT', 'INTERRUPT_ACKNOWLEDGED']
      }
    }
  },
  'PageResponseEvent': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/Event'
        }
      }
    }
  },
  'EventFull': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'instanceId': {
        'type': 'string'
      },
      'processingStartOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'threadId': {
        'type': 'integer',
        'format': 'int64'
      },
      'triggerId': {
        'type': 'integer',
        'format': 'int64'
      },
      'type': {
        'type': 'string',
        'enum': ['REST', 'CRON', 'RUN_PROCESS', 'MESSAGING', 'EVENT_HANDLER']
      },
      'status': {
        'type': 'string',
        'enum': ['NEW', 'FETCHED', 'IN_PROGRESS', 'ON_HOLD', 'INTERRUPT_REQUEST', 'FAILED', 'SUCCESSFUL', 'PROCESS_INACTIVE', 'TIMEOUT', 'INTERRUPT_ACKNOWLEDGED']
      },
      'receivingInstanceId': {
        'type': 'string'
      },
      'parentEventId': {
        'type': 'integer',
        'format': 'int64'
      },
      'parentProcessId': {
        'type': 'integer',
        'format': 'int64'
      },
      'input': {
        'type': 'object'
      },
      'output': {
        'type': 'object'
      },
      'respondSent': {
        'type': 'boolean'
      },
      'exceptionMessage': {
        'type': 'string'
      },
      'exceptionStackTrace': {
        'type': 'string'
      },
      'priority': {
        'type': 'integer',
        'format': 'int32'
      },
      'shouldFinishBy': {
        'type': 'string',
        'format': 'date-time'
      },
      'finishedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'heartbeat': {
        'type': 'string',
        'format': 'date-time'
      }
    }
  },
  'EventIdProcessId': {
    'type': 'object',
    'properties': {
      'eventId': {
        'type': 'integer',
        'format': 'int64'
      },
      'processId': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'PageResponseEventIdProcessId': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/EventIdProcessId'
        }
      }
    }
  },
  'EventStatusStatistics': {
    'type': 'object',
    'properties': {
      'status': {
        'type': 'string',
        'enum': ['NEW', 'FETCHED', 'IN_PROGRESS', 'ON_HOLD', 'INTERRUPT_REQUEST', 'FAILED', 'SUCCESSFUL', 'PROCESS_INACTIVE', 'TIMEOUT', 'INTERRUPT_ACKNOWLEDGED']
      },
      'count': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'PageResponseProcessIdName': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ProcessIdName'
        }
      }
    }
  },
  'ProcessIdName': {
    'type': 'object',
    'properties': {
      'processId': {
        'type': 'integer',
        'format': 'int64'
      },
      'processName': {
        'type': 'string'
      },
      'eventsCount': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'PageResponseResourceStatus': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceStatus'
        }
      }
    }
  },
  'Entity': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'fields': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/EntityField'
        }
      },
      'credentialId': {
        'type': 'integer',
        'format': 'int64'
      },
      'isGdprRelevant': {
        'type': 'boolean'
      },
      'systemPermissionRoles': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          '$ref': '#/definitions/EntitySystemPermissionRole'
        }
      }
    }
  },
  'EntityField': {
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'dataType': {
        'type': 'string',
        'enum': ['VARCHAR', 'TIMESTAMP', 'BIGINT', 'INTEGER', 'DOUBLE', 'FLOAT', 'JSON', 'BOOLEAN']
      },
      'searchType': {
        'type': 'string',
        'enum': ['NO', 'OPTIONAL', 'REQUIRED']
      },
      'defaultValue': {
        'type': 'string'
      },
      'matchingPattern': {
        'type': 'string',
        'enum': ['EXACT', 'BEGINNING', 'END', 'EVERYWHERE', 'BETWEEN']
      },
      'relation': {
        '$ref': '#/definitions/RelationId'
      },
      'primaryKey': {
        'type': 'boolean'
      },
      'sortable': {
        'type': 'boolean'
      },
      'unique': {
        'type': 'boolean'
      },
      'required': {
        'type': 'boolean'
      }
    }
  },
  'EntitySystemPermissionRole': {
    'type': 'object',
    'properties': {
      'roleId': {
        'type': 'integer',
        'format': 'int64'
      },
      'permissionType': {
        'type': 'string',
        'enum': ['READ', 'CREATE', 'UPDATE', 'DELETE']
      }
    }
  },
  'RelationId': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'type': {
        'type': 'string',
        'enum': ['ONE_TO_ONE', 'ONE_TO_MANY', 'MANY_TO_ONE', 'MANY_TO_MANY']
      }
    }
  },
  'EntityResourceIdName': {
    'type': 'object',
    'properties': {
      'resourceId': {
        'type': 'integer',
        'format': 'int64'
      },
      'resourceName': {
        'type': 'string'
      },
      'resourceType': {
        'type': 'string',
        'enum': ['VALIDATION_RULE', 'PROCESS', 'TRIGGER_REST', 'TRIGGER_MESSAGING']
      }
    }
  },
  'EntityWithPermissionRoles': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'name': {
        'type': 'string'
      },
      'createdOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'modifiedOn': {
        'type': 'string',
        'format': 'date-time'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceRole'
        }
      },
      'status': {
        'type': 'string',
        'enum': ['ACTIVE', 'INACTIVE']
      },
      'hasRead': {
        'type': 'boolean'
      },
      'hasCreate': {
        'type': 'boolean'
      },
      'hasUpdate': {
        'type': 'boolean'
      },
      'hasDelete': {
        'type': 'boolean'
      }
    }
  },
  'PageResponseEntityWithPermissionRoles': {
    'type': 'object',
    'properties': {
      'meta': {
        '$ref': '#/definitions/MetaPageResponse'
      },
      'items': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/EntityWithPermissionRoles'
        }
      }
    }
  },
  'CreateCaptcha': {
    'type': 'object',
    'properties': {
      'key': {
        'type': 'string'
      },
      'image': {
        'type': 'string'
      }
    }
  },
  'DeleteResourceResult': {
    'type': 'object',
    'properties': {
      'id': {
        'type': 'integer',
        'format': 'int64'
      },
      'usages': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceIdName'
        }
      }
    }
  },
  'DeleteRoleResult': {
    'type': 'object',
    'properties': {
      'relatedResources': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceIdNameProj'
        }
      },
      'relatedUsers': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/IdNameProj'
        }
      }
    }
  },
  'ResourceIdNameProj': {
    'type': 'object',
    'properties': {
      'type': {
        'type': 'string'
      },
      'name': {
        'type': 'string'
      },
      'id': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'DeleteEntityResourceResult': {
    'type': 'object',
    'properties': {
      'resourceId': {
        'type': 'integer',
        'format': 'int64'
      },
      'resourceName': {
        'type': 'string'
      },
      'resourceType': {
        'type': 'string',
        'enum': ['PROCESS_CREDENTIAL', 'TRIGGER_CRON', 'TRIGGER_REST', 'TRIGGER_MESSAGING', 'TRIGGER_EVENT_HANDLER', 'TEXT_TEMPLATE', 'GLOBAL_TRANSLATION', 'SETTING', 'MODULE', 'VALIDATION_RULE', 'PLUGIN', 'LIBRARY', 'ENTITY', 'PROCESS']
      },
      'usages': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ResourceIdName'
        }
      }
    }
  },
  'UpdateCredentialFields': {
    'required': ['name', 'queryName', 'roles', 'type', 'values'],
    'type': 'object',
    'properties': {
      'type': {
        'type': 'string',
        'enum': ['JDBC', 'MONGODB', 'TWILIO', 'SLACK', 'SMTP', 'IMAP', 'REST', 'REST_BASIC', 'REST_BEARER', 'PAYMENT_SENSE_PAC', 'PAYMENT_SENSE_CONNECT_E', 'FLOWY', 'PLUGIN', 'AWS', 'SECURITY', 'SCRIPT', 'JWT', 'OPEN_AI', 'LDAP', 'KAFKA', 'JMS', 'RABBITMQ', 'MQTT', 'FTP', 'SFTP', 'SSH', 'SSH_KEY', 'OUTLOOK_CALENDAR']
      },
      'name': {
        'type': 'string'
      },
      'values': {
        'type': 'object'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'queryName': {
        'type': 'string'
      }
    }
  },
  'CredentialNameFields': {
    'required': ['queryName'],
    'type': 'object',
    'properties': {
      'queryName': {
        'type': 'string'
      }
    }
  },
  'CreateCredentialFields': {
    'required': ['name', 'roles', 'targetObject', 'type', 'values'],
    'type': 'object',
    'properties': {
      'type': {
        'type': 'string',
        'enum': ['JDBC', 'MONGODB', 'TWILIO', 'SLACK', 'SMTP', 'IMAP', 'REST', 'REST_BASIC', 'REST_BEARER', 'PAYMENT_SENSE_PAC', 'PAYMENT_SENSE_CONNECT_E', 'FLOWY', 'PLUGIN', 'AWS', 'SECURITY', 'SCRIPT', 'JWT', 'OPEN_AI', 'LDAP', 'KAFKA', 'JMS', 'RABBITMQ', 'MQTT', 'FTP', 'SFTP', 'SSH', 'SSH_KEY', 'OUTLOOK_CALENDAR']
      },
      'name': {
        'type': 'string'
      },
      'values': {
        'type': 'object'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      }
    }
  },
  'CredentialFields': {
    'required': ['name', 'roles', 'type', 'values'],
    'type': 'object',
    'properties': {
      'type': {
        'type': 'string',
        'enum': ['JDBC', 'MONGODB', 'TWILIO', 'SLACK', 'SMTP', 'IMAP', 'REST', 'REST_BASIC', 'REST_BEARER', 'PAYMENT_SENSE_PAC', 'PAYMENT_SENSE_CONNECT_E', 'FLOWY', 'PLUGIN', 'AWS', 'SECURITY', 'SCRIPT', 'JWT', 'OPEN_AI', 'LDAP', 'KAFKA', 'JMS', 'RABBITMQ', 'MQTT', 'FTP', 'SFTP', 'SSH', 'SSH_KEY', 'OUTLOOK_CALENDAR']
      },
      'name': {
        'type': 'string'
      },
      'values': {
        'type': 'object'
      },
      'roles': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/RoleIdPermission'
        }
      }
    }
  },
  'ExceptionCatch': {
    'required': ['exceptions'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'steps': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/StepStepProperties'
        }
      },
      'exceptions': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      }
    }
  },
  'SwitchCondition': {
    'required': ['query', 'steps'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'query': {
        'type': 'string'
      },
      'steps': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/StepStepProperties'
        }
      }
    }
  },
  'QRStepProperties': {
    'required': ['content', 'errorCorrectionLevel', 'size', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'size': {
        'type': 'integer',
        'format': 'int32'
      },
      'content': {
        'type': 'string'
      },
      'errorCorrectionLevel': {
        'type': 'string',
        'enum': ['LOW', 'MEDIUM', 'QUARTILE', 'HIGH']
      }
    }
  },
  'KafkaMessagingStepConfig': {
    'required': ['messageKey'],
    'type': 'object',
    'properties': {
      'messageKey': {
        'type': 'string'
      }
    }
  },
  'JmsMessagingStepConfig': {
    'type': 'object',
    'properties': {
      'deliveryMode': {
        'type': 'string',
        'enum': ['NON_PERSISTENT', 'PERSISTENT']
      },
      'priority': {
        'type': 'integer',
        'format': 'int32'
      },
      'timeToLive': {
        'type': 'integer',
        'format': 'int64'
      }
    }
  },
  'MqttMessagingStepConfig': {
    'type': 'object',
    'properties': {
      'qos': {
        'maximum': 2,
        'minimum': 0,
        'type': 'integer',
        'format': 'int32'
      },
      'retained': {
        'type': 'boolean'
      }
    }
  },
  'CrossReferencePayment': {
    'required': ['crossReference'],
    'type': 'object',
    'properties': {
      'crossReference': {
        'type': 'string'
      },
      'cv2': {
        'type': 'string'
      }
    }
  },
  'PaymentSenseConnectEAccessTokenParameters': {
    'required': ['currencyCode', 'merchantUrl', 'orderId', 'transactionType'],
    'type': 'object',
    'properties': {
      'merchantUrl': {
        'type': 'string'
      },
      'currencyCode': {
        'pattern': '\\d\\d\\d',
        'type': 'string'
      },
      'amount': {
        'type': 'integer',
        'format': 'int32'
      },
      'transactionType': {
        'type': 'string',
        'enum': ['SALE', 'REFUND', 'PREAUTH', 'COLLECTION', 'VOID']
      },
      'orderId': {
        'type': 'string'
      },
      'transactionSource': {
        'type': 'string',
        'enum': ['MOTO']
      },
      'merchantTransactionId': {
        'type': 'string'
      },
      'orderDescription': {
        'type': 'string'
      },
      'userAgent': {
        'type': 'string'
      },
      'userEmailAddress': {
        'type': 'string'
      },
      'userPhoneNumber': {
        'type': 'string'
      },
      'userIpAddress': {
        'type': 'string'
      },
      'userAddress1': {
        'type': 'string'
      },
      'userAddress2': {
        'type': 'string'
      },
      'userAddress3': {
        'type': 'string'
      },
      'userAddress4': {
        'type': 'string'
      },
      'userCity': {
        'type': 'string'
      },
      'userState': {
        'type': 'string'
      },
      'userPostcode': {
        'type': 'string'
      },
      'userCountryCode': {
        'pattern': '^$|\\d{3}$',
        'type': 'string'
      },
      'shippingDetails': {
        '$ref': '#/definitions/ShippingDetails'
      },
      'newTransaction': {
        'type': 'boolean'
      },
      'crossReference': {
        'type': 'string'
      },
      'webHookUrl': {
        'type': 'string'
      },
      'metaData': {
        'type': 'object',
        'additionalProperties': {
          'type': 'object'
        }
      },
      'waitPreExecute': {
        'type': 'boolean'
      },
      'customerId': {
        'type': 'string'
      }
    }
  },
  'PaymentSenseConnectEParameters': {
    'required': ['accessToken'],
    'type': 'object',
    'properties': {
      'accessToken': {
        'type': 'string'
      }
    }
  },
  'PaymentSenseConnectECrossReferenceParameters': {
    'required': ['accessToken'],
    'type': 'object',
    'properties': {
      'accessToken': {
        'type': 'string'
      },
      'crossReferencePayment': {
        '$ref': '#/definitions/CrossReferencePayment'
      }
    }
  },
  'ShippingDetails': {
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'address': {
        '$ref': '#/definitions/ShippingDetailsAddress'
      }
    }
  },
  'ShippingDetailsAddress': {
    'type': 'object',
    'properties': {
      'userAddress1': {
        'type': 'string'
      },
      'userAddress2': {
        'type': 'string'
      },
      'userAddress3': {
        'type': 'string'
      },
      'userAddress4': {
        'type': 'string'
      },
      'userCity': {
        'type': 'string'
      },
      'userState': {
        'type': 'string'
      },
      'userPostcode': {
        'type': 'string'
      },
      'userCountryCode': {
        'pattern': '^$|\\d{3}$',
        'type': 'string'
      }
    }
  },
  'PaymentSensePacTerminalIdParameters': {
    'required': ['terminalId'],
    'type': 'object',
    'properties': {
      'terminalId': {
        'type': 'string'
      }
    }
  },
  'StartTransaction': {
    'required': ['transactionType'],
    'type': 'object',
    'properties': {
      'transactionType': {
        'type': 'string',
        'enum': ['SALE', 'REFUND', 'DUPLICATE', 'PRE_AUTH', 'COMPLETION', 'ACCOUNT_VERIFICATION']
      },
      'currency': {
        'type': 'string',
        'enum': ['GBP', 'EUR']
      },
      'amount': {
        'maximum': 99999999,
        'minimum': 1,
        'type': 'integer',
        'format': 'int32'
      },
      'amountCashback': {
        'maximum': 10000,
        'minimum': 0,
        'type': 'integer',
        'format': 'int32'
      },
      'cardholderNotPresent': {
        'type': 'boolean'
      },
      'authCode': {
        'type': 'string'
      },
      'amountPreAuthorisedTotal': {
        'maximum': 99999999,
        'minimum': 1,
        'type': 'integer',
        'format': 'int32'
      }
    }
  },
  'PaymentSensePacReportParameters': {
    'required': ['reportType', 'terminalId'],
    'type': 'object',
    'properties': {
      'terminalId': {
        'type': 'string'
      },
      'reportType': {
        'type': 'string',
        'enum': ['END_OF_DAY', 'BANKING', 'X_BALANCE', 'Z_BALANCE']
      }
    }
  },
  'PaymentSensePacRequestIdParameters': {
    'required': ['requestId', 'terminalId'],
    'type': 'object',
    'properties': {
      'terminalId': {
        'type': 'string'
      },
      'requestId': {
        'type': 'string'
      }
    }
  },
  'PaymentSensePacStartTransactionParameters': {
    'required': ['requestId', 'terminalId', 'transaction'],
    'type': 'object',
    'properties': {
      'terminalId': {
        'type': 'string'
      },
      'requestId': {
        'type': 'string'
      },
      'transaction': {
        '$ref': '#/definitions/StartTransaction'
      }
    }
  },
  'PaymentSensePacSignatureParameters': {
    'required': ['accepted', 'requestId', 'terminalId'],
    'type': 'object',
    'properties': {
      'terminalId': {
        'type': 'string'
      },
      'requestId': {
        'type': 'string'
      },
      'accepted': {
        'type': 'boolean'
      }
    }
  },
  'ForeachStepProperties': {
    'required': ['query', 'recordName', 'steps'],
    'type': 'object',
    'properties': {
      'query': {
        'type': 'string'
      },
      'steps': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/StepStepProperties'
        }
      },
      'recordName': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      }
    }
  },
  'ImapStepProperties': {
    'required': ['action', 'credentialName'],
    'type': 'object',
    'properties': {
      'scriptEngine': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      },
      'credentialName': {
        'type': 'string'
      },
      'action': {
        'type': 'string',
        'enum': ['SEARCH', 'MARK_READ', 'MARK_UNREAD', 'DELETE', 'ATTACHMENTS']
      },
      'folder': {
        'type': 'string'
      },
      'sslEnable': {
        'type': 'boolean'
      },
      'fields': {
        'type': 'object'
      }
    }
  },
  'SearchFilters': {
    'required': ['targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'sender': {
        'type': 'string'
      },
      'recipient': {
        'type': 'string'
      },
      'subject': {
        'type': 'string'
      },
      'body': {
        'type': 'string'
      },
      'readType': {
        'type': 'string',
        'enum': ['READ', 'UNREAD', 'ALL']
      },
      'maxResults': {
        'type': 'string'
      }
    }
  },
  'EmailIdFilters': {
    'required': ['emailId'],
    'type': 'object',
    'properties': {
      'emailId': {
        'type': 'string'
      }
    }
  },
  'AttachmentsFilters': {
    'required': ['emailId', 'targetObject'],
    'type': 'object',
    'properties': {
      'emailId': {
        'type': 'string'
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      }
    }
  },
  'EncoderSourceTarget': {
    'required': ['sourceObject', 'targetObject'],
    'type': 'object',
    'properties': {
      'sourceObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'sourceEncoding': {
        'type': 'string'
      },
      'targetEncoding': {
        'type': 'string'
      }
    }
  },
  'ReadS3StepFields': {
    'required': ['key', 'targetObject'],
    'type': 'object',
    'properties': {
      'path': {
        'type': 'string'
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'key': {
        'type': 'string'
      }
    }
  },
  'ListS3StepFields': {
    'required': ['targetObject'],
    'type': 'object',
    'properties': {
      'path': {
        'type': 'string'
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      }
    }
  },
  'CreateS3StepFields': {
    'required': ['content', 'key'],
    'type': 'object',
    'properties': {
      'path': {
        'type': 'string'
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'key': {
        'type': 'string'
      },
      'content': {
        'type': 'string'
      }
    }
  },
  'DeleteS3StepFields': {
    'required': ['key'],
    'type': 'object',
    'properties': {
      'path': {
        'type': 'string'
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'key': {
        'type': 'string'
      }
    }
  },
  'PythonScript': {
    'required': ['result'],
    'type': 'object',
    'properties': {
      'execution': {
        'type': 'string'
      },
      'result': {
        'type': 'string'
      }
    }
  },
  'SwitchStepProperties': {
    'required': ['conditions'],
    'type': 'object',
    'properties': {
      'scriptEngine': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      },
      'conditions': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/SwitchCondition'
        }
      }
    }
  },
  'JwtStepProperties': {
    'required': ['credentialName', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'credentialName': {
        'type': 'string'
      },
      'payload': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      }
    }
  },
  'OutlookCalendarAttendee': {
    'required': ['address', 'name'],
    'type': 'object',
    'properties': {
      'address': {
        'type': 'string'
      },
      'name': {
        'type': 'string'
      },
      'type': {
        'type': 'string'
      }
    }
  },
  'OutlookCalendarIdAndUserIdFields': {
    'required': ['userId'],
    'type': 'object',
    'properties': {
      'calendarId': {
        'type': 'string'
      },
      'userId': {
        'type': 'string'
      }
    }
  },
  'OutlookCalendarTime': {
    'required': ['dateTime', 'timeZone'],
    'type': 'object',
    'properties': {
      'dateTime': {
        'type': 'string'
      },
      'timeZone': {
        'type': 'string'
      }
    }
  },
  'OutlookCalendarAuthFields': {
    'type': 'object',
    'properties': {
      'expiresIn': {
        'maximum': 3599,
        'minimum': 1,
        'type': 'integer',
        'format': 'int32'
      }
    }
  },
  'OutlookCalendarDeleteFields': {
    'required': ['eventId', 'userId'],
    'type': 'object',
    'properties': {
      'calendarId': {
        'type': 'string'
      },
      'userId': {
        'type': 'string'
      },
      'eventId': {
        'type': 'string'
      }
    }
  },
  'OutlookCalendarBody': {
    'required': ['content', 'contentType'],
    'type': 'object',
    'properties': {
      'contentType': {
        'type': 'string'
      },
      'content': {
        'type': 'string'
      }
    }
  },
  'OutlookCalendarListFields': {
    'required': ['targetObject', 'userId'],
    'type': 'object',
    'properties': {
      'calendarId': {
        'type': 'string'
      },
      'userId': {
        'type': 'string'
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      }
    }
  },
  'OutlookCalendarStepProperties': {
    'required': ['action', 'credentialName', 'fields'],
    'type': 'object',
    'properties': {
      'credentialName': {
        'type': 'string'
      },
      'action': {
        'type': 'string',
        'enum': ['CREATE', 'LIST', 'UPDATE', 'DELETE', 'AUTH']
      },
      'fields': {
        'type': 'object'
      }
    }
  },
  'OutlookCalendarRecurrenceRange': {
    'required': ['endDate', 'startDate'],
    'type': 'object',
    'properties': {
      'startDate': {
        'type': 'string'
      },
      'endDate': {
        'type': 'string'
      }
    }
  },
  'OutlookCalendarRecurrencePattern': {
    'required': ['daysOfWeek', 'interval', 'type'],
    'type': 'object',
    'properties': {
      'type': {
        'type': 'string'
      },
      'interval': {
        'type': 'string'
      },
      'daysOfWeek': {
        'type': 'object'
      }
    }
  },
  'OutlookCalendarCreateFields': {
    'required': ['attendees', 'body', 'end', 'start', 'subject', 'userId'],
    'type': 'object',
    'properties': {
      'calendarId': {
        'type': 'string'
      },
      'userId': {
        'type': 'string'
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'subject': {
        'type': 'string'
      },
      'body': {
        '$ref': '#/definitions/OutlookCalendarBody'
      },
      'start': {
        '$ref': '#/definitions/OutlookCalendarTime'
      },
      'end': {
        '$ref': '#/definitions/OutlookCalendarTime'
      },
      'location': {
        'type': 'string'
      },
      'attendees': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/OutlookCalendarAttendee'
        }
      },
      'reminderMinutesBeforeStart': {
        'type': 'string'
      },
      'recurrence': {
        '$ref': '#/definitions/OutlookCalendarRecurrence'
      }
    }
  },
  'OutlookCalendarUpdateFields': {
    'required': ['attendees', 'body', 'end', 'eventId', 'start', 'subject', 'userId'],
    'type': 'object',
    'properties': {
      'calendarId': {
        'type': 'string'
      },
      'userId': {
        'type': 'string'
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'subject': {
        'type': 'string'
      },
      'body': {
        '$ref': '#/definitions/OutlookCalendarBody'
      },
      'start': {
        '$ref': '#/definitions/OutlookCalendarTime'
      },
      'end': {
        '$ref': '#/definitions/OutlookCalendarTime'
      },
      'location': {
        'type': 'string'
      },
      'attendees': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/OutlookCalendarAttendee'
        }
      },
      'reminderMinutesBeforeStart': {
        'type': 'string'
      },
      'recurrence': {
        '$ref': '#/definitions/OutlookCalendarRecurrence'
      },
      'eventId': {
        'type': 'string'
      }
    }
  },
  'OutlookCalendarRecurrence': {
    'required': ['pattern', 'range'],
    'type': 'object',
    'properties': {
      'pattern': {
        '$ref': '#/definitions/OutlookCalendarRecurrencePattern'
      },
      'range': {
        '$ref': '#/definitions/OutlookCalendarRecurrenceRange'
      }
    }
  },
  'CredentialProperties': {
    'required': ['action', 'fields'],
    'type': 'object',
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['CREATE', 'UPDATE', 'DISABLE', 'ENABLE']
      },
      'fields': {
        'type': 'object',
        'description': 'CREATE: CreateCredentialFields, UPDATE: UpdateCredentialFields, DISABLE: CredentialIdFields, ENABLE: CredentialIdFields'
      }
    }
  },
  'FromTemplateImageStepFields': {
    'required': ['textTemplate'],
    'type': 'object',
    'properties': {
      'variables': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      },
      'locale': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['JPEG', 'PNG', 'BMP', 'GIF']
      },
      'textTemplate': {
        'type': 'string'
      },
      'height': {
        'type': 'integer',
        'format': 'int32'
      },
      'width': {
        'type': 'integer',
        'format': 'int32'
      }
    }
  },
  'ConvertImageStepFields': {
    'required': ['source', 'type'],
    'type': 'object',
    'properties': {
      'source': {
        'type': 'string'
      },
      'type': {
        'type': 'string',
        'enum': ['JPEG', 'PNG', 'BMP', 'GIF']
      }
    }
  },
  'SourceImageStepFields': {
    'required': ['source'],
    'type': 'object',
    'properties': {
      'source': {
        'type': 'string'
      }
    }
  },
  'RescaleImageStepFields': {
    'required': ['height', 'source', 'width'],
    'type': 'object',
    'properties': {
      'source': {
        'type': 'string'
      },
      'height': {
        'type': 'string'
      },
      'width': {
        'type': 'string'
      }
    }
  },
  'ICalendarAlarm': {
    'required': ['alarmTime'],
    'type': 'object',
    'properties': {
      'alarmTime': {
        'type': 'string'
      },
      'alarmDescription': {
        'type': 'string'
      }
    }
  },
  'ICalendarRecurrence': {
    'required': ['frequency', 'frequencyInterval'],
    'type': 'object',
    'properties': {
      'frequency': {
        'type': 'string'
      },
      'frequencyInterval': {
        'type': 'string'
      }
    }
  },
  'ICalendarParticipant': {
    'required': ['email', 'name'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'email': {
        'type': 'string'
      }
    }
  },
  'ICalendarStepProperties': {
    'required': ['endTime', 'organizer', 'participants', 'startTime', 'summary', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'organizer': {
        'type': 'string'
      },
      'summary': {
        'type': 'string'
      },
      'description': {
        'type': 'string'
      },
      'startTime': {
        'type': 'string'
      },
      'endTime': {
        'type': 'string'
      },
      'participants': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ICalendarParticipant'
        }
      },
      'alarm': {
        '$ref': '#/definitions/ICalendarAlarm'
      },
      'recurrence': {
        '$ref': '#/definitions/ICalendarRecurrence'
      },
      'status': {
        'type': 'string'
      },
      'url': {
        'type': 'string'
      },
      'location': {
        'type': 'string'
      }
    }
  },
  'TryCatchStepProperties': {
    'required': ['catch', 'try'],
    'type': 'object',
    'properties': {
      'exceptionMessage': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'try': {
        '$ref': '#/definitions/NamedSteps'
      },
      'catch': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ExceptionCatch'
        }
      },
      'finally': {
        '$ref': '#/definitions/NamedSteps'
      }
    }
  },
  'BodyTemplate': {
    'required': ['textTemplate'],
    'type': 'object',
    'properties': {
      'textTemplate': {
        'type': 'string'
      },
      'locale': {
        'maxLength': 2,
        'minLength': 2,
        'type': 'string'
      },
      'variables': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      }
    }
  },
  'DecompressZipFields': {
    'required': ['sourceObject'],
    'type': 'object',
    'properties': {
      'sourceObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      }
    }
  },
  'CompressZipFields': {
    'required': ['entries'],
    'type': 'object',
    'properties': {
      'entries': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ZipElement'
        }
      }
    }
  },
  'FindParameters': {
    'type': 'object',
    'properties': {
      'query': {
        'type': 'string'
      },
      'projection': {
        'type': 'string'
      },
      'skip': {
        'type': 'string'
      },
      'limit': {
        'type': 'string'
      },
      'sort': {
        '$ref': '#/definitions/MongodbSort'
      }
    }
  },
  'MongodbStepProperties': {
    'required': ['action', 'collectionName', 'credentialName', 'parameters'],
    'type': 'object',
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['FIND', 'INSERT', 'UPDATE', 'DELETE', 'COUNT']
      },
      'credentialName': {
        'type': 'string'
      },
      'collectionName': {
        'type': 'string'
      },
      'targetObject': {
        'type': 'string'
      },
      'parameters': {
        '$ref': '#/definitions/MongodbParameters'
      },
      'singleResult': {
        'type': 'boolean'
      }
    }
  },
  'MongodbSort': {
    'type': 'object',
    'properties': {
      'field': {
        'type': 'string'
      },
      'direction': {
        'type': 'string'
      }
    }
  },
  'QueryUpdateParameters': {
    'type': 'object',
    'properties': {
      'queryUpdate': {
        'type': 'string'
      }
    }
  },
  'UpdateParameters': {
    'type': 'object',
    'properties': {
      'query': {
        'type': 'string'
      },
      'queryUpdate': {
        'type': 'string'
      }
    }
  },
  'QueryParameters': {
    'type': 'object',
    'properties': {
      'query': {
        'type': 'string'
      }
    }
  },
  'ZipStepProperties': {
    'required': ['action', 'fields', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'action': {
        'type': 'string',
        'enum': ['COMPRESS', 'DECOMPRESS']
      },
      'fields': {
        'type': 'object',
        'description': 'COMPRESS: CompressZipFields, DECOMPRESS: DecompressZipFields'
      }
    }
  },
  'TargetObjectProperties': {
    'required': ['targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      }
    }
  },
  'PluginProperties': {
    'required': ['action', 'pluginName'],
    'type': 'object',
    'properties': {
      'action': {
        'type': 'string'
      },
      'pluginName': {
        'type': 'string'
      },
      'pluginSettingName': {
        'type': 'string'
      },
      'pluginCredentialName': {
        'type': 'string'
      },
      'input': {
        'type': 'object',
        'additionalProperties': {
          'type': 'object'
        }
      },
      'output': {
        'type': 'object',
        'additionalProperties': {
          'type': 'object'
        }
      }
    }
  },
  'MessagingStepProperties': {
    'required': ['credentialName', 'message', 'service'],
    'type': 'object',
    'properties': {
      'scriptEngine': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      },
      'credentialName': {
        'type': 'string'
      },
      'service': {
        'type': 'string',
        'enum': ['KAFKA', 'JMS', 'RABBITMQ', 'AWS_SQS', 'MQTT']
      },
      'message': {
        'type': 'string'
      },
      'config': {
        'type': 'object'
      }
    }
  },
  'OcrStepProperties': {
    'required': ['image', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'language': {
        'type': 'string'
      },
      'image': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      }
    }
  },
  'PythonStepProperties': {
    'required': ['set'],
    'type': 'object',
    'properties': {
      'credentialName': {
        'type': 'string'
      },
      'librariesNames': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'set': {
        'type': 'object',
        'additionalProperties': {
          '$ref': '#/definitions/PythonScript'
        }
      }
    }
  },
  'ChatGPTMessage': {
    'required': ['content', 'role'],
    'type': 'object',
    'properties': {
      'role': {
        'type': 'string',
        'enum': ['SYSTEM', 'USER', 'ASSISTANT']
      },
      'content': {
        'type': 'string'
      }
    }
  },
  'ChatGPTStepProperties': {
    'required': ['credentialName', 'messages', 'model', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'scriptEngine': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      },
      'credentialName': {
        'type': 'string'
      },
      'model': {
        'type': 'string'
      },
      'messages': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/ChatGPTMessage'
        }
      }
    }
  },
  'ExecuteExternalCommandStepProperties': {
    'required': ['command'],
    'type': 'object',
    'properties': {
      'scriptEngine': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      },
      'command': {
        'type': 'string'
      },
      'input': {
        'type': 'string'
      },
      'resultCode': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'resultErrors': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      }
    }
  },
  'EscCharsProperties': {
    'required': ['sourceObject', 'targetObject'],
    'type': 'object',
    'properties': {
      'sourceObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      }
    }
  },
  'EmailStepProperties': {
    'required': ['credentialName', 'textTemplate', 'to', 'variables'],
    'type': 'object',
    'properties': {
      'to': {
        'type': 'string'
      },
      'from': {
        'type': 'string'
      },
      'cc': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'bcc': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'credentialName': {
        'type': 'string'
      },
      'textTemplate': {
        'type': 'string'
      },
      'locale': {
        'maxLength': 2,
        'minLength': 2,
        'type': 'string'
      },
      'async': {
        'type': 'boolean'
      },
      'variables': {
        'type': 'object',
        'additionalProperties': {
          'type': 'object'
        }
      },
      'attachments': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/EmailStepAttachment'
        }
      }
    }
  },
  'LdapBindFields': {
    'required': ['attributeValues', 'dn', 'objectClassAttributes'],
    'type': 'object',
    'properties': {
      'dn': {
        'type': 'string'
      },
      'attributeValues': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/AttributeValue'
        }
      },
      'objectClassAttributes': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      }
    }
  },
  'LdapSearchFields': {
    'required': ['filter', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'dn': {
        'type': 'string'
      },
      'filter': {
        'type': 'string'
      },
      'searchKey': {
        'type': 'string'
      }
    }
  },
  'LdapModifyFields': {
    'required': ['attributeValues', 'dn'],
    'type': 'object',
    'properties': {
      'dn': {
        'type': 'string'
      },
      'attributeValues': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/AttributeValue'
        }
      }
    }
  },
  'AttributeValue': {
    'required': ['name', 'value'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'value': {
        'type': 'string'
      }
    }
  },
  'LdapStepProperties': {
    'required': ['action', 'credentialName', 'fields'],
    'type': 'object',
    'properties': {
      'credentialName': {
        'type': 'string'
      },
      'action': {
        'type': 'string',
        'enum': ['BIND', 'SEARCH', 'MODIFY']
      },
      'fields': {
        'type': 'object'
      }
    }
  },
  'EmailStepAttachment': {
    'required': ['content', 'contentType', 'name'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'content': {
        'type': 'string'
      },
      'contentType': {
        'type': 'string',
        'enum': ['APPLICATION_JSON', 'APPLICATION_XML', 'APPLICATION_PDF', 'APPLICATION_ZIP', 'IMAGE_GIF', 'IMAGE_JPEG', 'IMAGE_PNG', 'IMAGE_SVG', 'TEXT_PLAIN', 'TEXT_ICS', 'TEXT_CSV']
      }
    }
  },
  'YamlStepProperties': {
    'required': ['action', 'source', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'action': {
        'type': 'string',
        'enum': ['JSON_TO_YAML', 'YAML_TO_JSON']
      },
      'source': {
        'type': 'string'
      }
    }
  },
  'FtpStepProperties': {
    'required': ['action', 'credentialName', 'path', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'credentialName': {
        'type': 'string'
      },
      'path': {
        'type': 'string'
      },
      'action': {
        'type': 'string',
        'enum': ['DOWNLOAD', 'UPLOAD', 'LIST', 'DELETE']
      }
    }
  },
  'XmlStepProperties': {
    'required': ['action', 'sourceObject', 'targetObject'],
    'type': 'object',
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['JSON_TO_XML', 'XML_TO_JSON']
      },
      'sourceObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      }
    }
  },
  'BaseLanguageStepProperties': {
    'type': 'object',
    'properties': {
      'credentialName': {
        'type': 'string'
      },
      'librariesNames': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'type': 'string'
        }
      }
    }
  },
  'WhileStepProperties': {
    'required': ['query', 'steps'],
    'type': 'object',
    'properties': {
      'scriptEngine': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      },
      'query': {
        'type': 'string'
      },
      'steps': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/StepStepProperties'
        }
      }
    }
  },
  'EncoderStepProperties': {
    'required': ['action', 'converts'],
    'type': 'object',
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['URL_ENCODE', 'URL_DECODE', 'ENCODE_HTML_SPECIAL_CHARS', 'CHANGE_ENCODING']
      },
      'converts': {
        'type': 'array',
        'description': 'URL_ENCODE: SourceTarget,\nURL_DECODE: SourceTarget,\nENCODE_HTML_SPECIAL_CHARS: SourceTarget,\nCHANGE_ENCODING: EncoderSourceTarget\n',
        'items': {
          '$ref': '#/definitions/EncoderSourceTarget'
        }
      }
    }
  },
  'FormattingStepProperties': {
    'required': ['action', 'converts', 'fields'],
    'type': 'object',
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['DECIMAL_FORMAT']
      },
      'converts': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/SourceTarget'
        }
      },
      'fields': {
        'type': 'object',
        'description': 'DECIMAL_FORMAT: DecimalFormatFields'
      }
    }
  },
  'LogStepProperties': {
    'required': ['message'],
    'type': 'object',
    'properties': {
      'message': {
        'type': 'string'
      }
    }
  },
  'StorageStepProperties': {
    'required': ['action', 'fields', 'key'],
    'type': 'object',
    'properties': {
      'scriptEngine': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      },
      'action': {
        'type': 'string',
        'enum': ['GET', 'UPSERT']
      },
      'key': {
        'type': 'string'
      },
      'fields': {
        'type': 'object',
        'description': 'GET: GetStorageStepFields, UPSERT: UpsertStorageStepFields'
      }
    }
  },
  'PdfStepProperties': {
    'required': ['targetObject', 'textTemplate'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'textTemplate': {
        'type': 'string'
      },
      'locale': {
        'maxLength': 2,
        'minLength': 2,
        'type': 'string'
      },
      'variables': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      }
    }
  },
  'SourceTarget': {
    'required': ['sourceObject', 'targetObject'],
    'type': 'object',
    'properties': {
      'sourceObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      }
    }
  },
  'RestStepProperties': {
    'required': ['action', 'encoding', 'restResponseCode', 'targetObject', 'targetUrl'],
    'type': 'object',
    'properties': {
      'scriptEngine': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      },
      'credentialName': {
        'type': 'string'
      },
      'targetUrl': {
        'type': 'string'
      },
      'action': {
        'type': 'string',
        'enum': ['GET', 'POST', 'PUT', 'PATCH', 'DELETE']
      },
      'queryParams': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      },
      'body': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      },
      'bodyScript': {
        'type': 'string'
      },
      'bodyTemplate': {
        '$ref': '#/definitions/BodyTemplate'
      },
      'headers': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'restResponseCode': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'timeout': {
        'type': 'integer',
        'format': 'int32'
      },
      'encoding': {
        'type': 'string'
      },
      'authType': {
        'type': 'string',
        'enum': ['BASIC', 'BEARER']
      }
    }
  },
  'UnsetVariablesStepProperties': {
    'required': ['variables'],
    'type': 'object',
    'properties': {
      'variables': {
        'type': 'array',
        'items': {
          'type': 'string'
        }
      }
    }
  },
  'UpsertStorageStepFields': {
    'required': ['expiresIn', 'value'],
    'type': 'object',
    'properties': {
      'expiresIn': {
        'type': 'string'
      },
      'value': {
        'type': 'string'
      }
    }
  },
  'GetStorageStepFields': {
    'required': ['targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      }
    }
  },
  'ProcessSettingProperties': {
    'required': ['processSettingName', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'processSettingName': {
        'type': 'string'
      }
    }
  },
  'LanguageStepProperties': {
    'required': ['set'],
    'type': 'object',
    'properties': {
      'credentialName': {
        'type': 'string'
      },
      'librariesNames': {
        'uniqueItems': true,
        'type': 'array',
        'items': {
          'type': 'string'
        }
      },
      'set': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      }
    }
  },
  'DecimalFormatFields': {
    'type': 'object',
    'properties': {
      'locale': {
        'type': 'object',
        'properties': {
          'language': {
            'type': 'string'
          },
          'script': {
            'type': 'string'
          },
          'variant': {
            'type': 'string'
          },
          'country': {
            'type': 'string'
          },
          'unicodeLocaleAttributes': {
            'uniqueItems': true,
            'type': 'array',
            'items': {
              'type': 'string'
            }
          },
          'unicodeLocaleKeys': {
            'uniqueItems': true,
            'type': 'array',
            'items': {
              'type': 'string'
            }
          },
          'displayLanguage': {
            'type': 'string'
          },
          'displayScript': {
            'type': 'string'
          },
          'displayCountry': {
            'type': 'string'
          },
          'displayVariant': {
            'type': 'string'
          },
          'displayName': {
            'type': 'string'
          },
          'extensionKeys': {
            'uniqueItems': true,
            'type': 'array',
            'items': {
              'type': 'string'
            }
          },
          'iso3Language': {
            'type': 'string'
          },
          'iso3Country': {
            'type': 'string'
          }
        }
      },
      'pattern': {
        'type': 'string'
      }
    }
  },
  'S3StepProperties': {
    'required': ['action', 'credentialName', 'fields'],
    'type': 'object',
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['LIST', 'CREATE', 'READ', 'DELETE']
      },
      'credentialName': {
        'type': 'string'
      },
      'fields': {
        '$ref': '#/definitions/S3StepFields'
      }
    }
  },
  'SshStepProperties': {
    'required': ['command', 'credentialName'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'credentialName': {
        'type': 'string'
      },
      'command': {
        'type': 'string'
      },
      'timeout': {
        'type': 'integer',
        'format': 'int32'
      }
    }
  },
  'PaymentSenseConnectEProperties': {
    'required': ['action', 'credentialName'],
    'type': 'object',
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['ACCESS_TOKEN', 'PAYMENT_DETAILS', 'RESUME_PAYMENT', 'CROSS_REFERENCE_PAYMENT', 'REVOKE_ACCESS_TOKEN', 'PAYMENT_METHODS']
      },
      'credentialName': {
        'type': 'string'
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'parameters': {
        'type': 'object',
        'description': 'ACCESS_TOKENS: PaymentSenseConnectEAccessTokenParameters,\nPAYMENT_DETAILS: PaymentSenseConnectEParameters,\nRESUME_PAYMENT: PaymentSenseConnectEParameters,\nCROSS_REFERENCE_PAYMENT: PaymentSenseConnectECrossReferenceParameters,\nREVOKE_ACCESS_TOKEN: PaymentSenseConnectEParameters,\nPAYMENT_METHODS: null\n'
      }
    }
  },
  'SecurityStepProperties': {
    'required': ['action', 'variables'],
    'type': 'object',
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['ENCRYPT', 'DECRYPT']
      },
      'variables': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      },
      'method': {
        'type': 'string',
        'enum': ['AES', 'HMAC_SHA256']
      },
      'credentialName': {
        'type': 'string'
      }
    }
  },
  'QueryBuilderStepProperties': {
    'required': ['queryConditions', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'scriptEngine': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      },
      'delimiter': {
        'type': 'string'
      },
      'queryConditions': {
        'type': 'array',
        'items': {
          '$ref': '#/definitions/QueryCondition'
        }
      }
    }
  },
  'UuidStepProperties': {
    'required': ['targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      }
    }
  },
  'ExecuteProcessStepProperties': {
    'required': ['processName'],
    'type': 'object',
    'properties': {
      'processName': {
        'type': 'string'
      },
      'action': {
        'type': 'string',
        'enum': ['SYNC', 'ASYNC', 'INLINE']
      },
      'input': {
        'type': 'object',
        'additionalProperties': {
          'type': 'object'
        }
      },
      'output': {
        'type': 'object',
        'additionalProperties': {
          'type': 'object'
        }
      },
      'childEventIdPath': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'priority': {
        'type': 'integer',
        'format': 'int32'
      }
    }
  },
  'PaymentSensePacProperties': {
    'required': ['action', 'credentialName'],
    'type': 'object',
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['GET_TERMINALS', 'GET_TERMINAL', 'START_TRANSACTION', 'GET_TRANSACTION', 'SIGNATURE', 'START_REPORT', 'CANCEL_TRANSACTION', 'GET_REPORT']
      },
      'credentialName': {
        'type': 'string'
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'parameters': {
        'type': 'object',
        'description': 'GET_TERMINALS: null,\nGET_TERMINAL: PaymentSensePacTerminalIdParameters,\nSTART_TRANSACTION: PaymentSensePacStartTransactionParameters,\nGET_TRANSACTION: PaymentSensePacRequestIdParameters,\nSIGNATURE: PaymentSensePacSignatureParameters,\nSTART_REPORT: PaymentSensePacReportParameters,\nCANCEL_TRANSACTION: PaymentSensePacRequestIdParameters,\nGET_REPORT: PaymentSensePacRequestIdParameters\n'
      }
    }
  },
  'SlackStepProperties': {
    'required': ['credentialName'],
    'type': 'object',
    'properties': {
      'scriptEngine': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      },
      'credentialName': {
        'type': 'string'
      },
      'message': {
        'type': 'string'
      },
      'locale': {
        'maxLength': 2,
        'minLength': 2,
        'type': 'string'
      },
      'textTemplate': {
        'type': 'string'
      },
      'variables': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      },
      'channelId': {
        'type': 'string'
      }
    }
  },
  'TwilioStepProperties': {
    'required': ['credentialName', 'from', 'to'],
    'type': 'object',
    'properties': {
      'scriptEngine': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      },
      'credentialName': {
        'type': 'string'
      },
      'message': {
        'type': 'string'
      },
      'locale': {
        'maxLength': 2,
        'minLength': 2,
        'type': 'string'
      },
      'textTemplate': {
        'type': 'string'
      },
      'variables': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      },
      'from': {
        'type': 'string'
      },
      'to': {
        'type': 'string'
      }
    }
  },
  'RolesStepFields': {
    'required': ['targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'roles': {
        'type': 'string'
      }
    }
  },
  'GenerateApiKeyUserStepFields': {
    'required': ['email', 'password', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'email': {
        'type': 'string'
      },
      'password': {
        'type': 'string'
      }
    }
  },
  'CreateUserStepFields': {
    'required': ['email', 'name', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'roles': {
        'type': 'string'
      },
      'name': {
        'type': 'string'
      },
      'email': {
        'type': 'string'
      }
    }
  },
  'ValidatePasswordUserStepFields': {
    'required': ['email', 'password', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'email': {
        'type': 'string'
      },
      'password': {
        'type': 'string'
      }
    }
  },
  'ValidateApiKeyUserStepFields': {
    'required': ['apiKey', 'email', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'email': {
        'type': 'string'
      },
      'apiKey': {
        'type': 'string'
      }
    }
  },
  'UserIdUserStepFields': {
    'required': ['targetObject', 'userId'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'userId': {
        'type': 'string'
      }
    }
  },
  'CheckEmailAvailabilityUserStepFields': {
    'required': ['email', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'email': {
        'type': 'string'
      }
    }
  },
  'UserStepProperties': {
    'required': ['action', 'fields'],
    'type': 'object',
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['CREATE_USER', 'CHECK_EMAIL_AVAILABILITY', 'GENERATE_API_KEY', 'VALIDATE_API_KEY', 'VALIDATE_PASSWORD', 'GET_USER_DETAILS', 'GET_USER_ROLES', 'GET_USERS_BY_ROLES']
      },
      'fields': {
        '$ref': '#/definitions/UserStepFields'
      }
    }
  },
  'JdbcStepProperties': {
    'required': ['action', 'credentialName', 'query'],
    'type': 'object',
    'properties': {
      'action': {
        'type': 'string',
        'enum': ['SELECT', 'UPDATE', 'INSERT', 'DELETE', 'CUSTOM']
      },
      'credentialName': {
        'type': 'string'
      },
      'query': {
        'type': 'string'
      },
      'targetObject': {
        'type': 'string'
      },
      'singleResult': {
        'type': 'boolean'
      }
    }
  },
  'GraphqlStepProperties': {
    'required': ['query', 'responseCode', 'targetObject', 'targetUrl'],
    'type': 'object',
    'properties': {
      'scriptEngine': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      },
      'credentialName': {
        'type': 'string'
      },
      'targetUrl': {
        'type': 'string'
      },
      'query': {
        'type': 'string'
      },
      'variables': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      },
      'headers': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'responseCode': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'timeout': {
        'type': 'integer',
        'format': 'int32'
      }
    }
  },
  'XlsStepProperties': {
    'required': ['source', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'action': {
        'type': 'string',
        'enum': ['XLS_TO_JSON', 'JSON_TO_XLS']
      },
      'source': {
        'type': 'string'
      },
      'includeHeaders': {
        'type': 'boolean'
      }
    }
  },
  'XsltStepProperties': {
    'required': ['targetObject', 'xml', 'xslt'],
    'type': 'object',
    'properties': {
      'xml': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'xslt': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      }
    }
  },
  'QueryCondition': {
    'required': ['condition', 'query'],
    'type': 'object',
    'properties': {
      'condition': {
        'type': 'string'
      },
      'query': {
        'type': 'string'
      }
    }
  },
  'CsvStepProperties': {
    'required': ['source', 'targetObject'],
    'type': 'object',
    'properties': {
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'action': {
        'type': 'string',
        'enum': ['JSON_TO_CSV', 'CSV_TO_JSON']
      },
      'source': {
        'type': 'string'
      },
      'filters': {
        'type': 'object',
        'additionalProperties': {
          'type': 'string'
        }
      },
      'includeHeaders': {
        'type': 'boolean'
      },
      'delimiter': {
        'type': 'string',
        'enum': ['COMMA', 'SEMICOLON', 'TAB', 'SPACE', 'PIPE', 'CUSTOM']
      },
      'customDelimiter': {
        'type': 'string'
      },
      'qualifier': {
        'type': 'string',
        'enum': ['NONE', 'SINGLE_QUOTES', 'DOUBLE_QUOTES']
      }
    }
  },
  'ZipElement': {
    'required': ['content', 'name'],
    'type': 'object',
    'properties': {
      'name': {
        'type': 'string'
      },
      'path': {
        'type': 'string'
      },
      'content': {
        'type': 'string'
      }
    }
  },
  'ImageStepProperties': {
    'required': ['action', 'targetObject'],
    'type': 'object',
    'properties': {
      'scriptEngine': {
        'type': 'string',
        'enum': ['JS', 'GROOVY', 'PYTHON']
      },
      'action': {
        'type': 'string',
        'enum': ['RESCALE', 'INFO', 'CONVERT', 'DOWNLOAD', 'FROM_TEMPLATE']
      },
      'targetObject': {
        'pattern': '\\$\\.[\\w.]+',
        'type': 'string'
      },
      'fields': {
        'type': 'object',
        'description': 'CONVERT: ConvertImageStepFields,\nRESCALE: RescaleImageStepFields,\nFROM_TEMPLATE: FromTemplateImageStepFields,\nINFO: SourceImageStepFields,\nDOWNLOAD: SourceImageStepFields\n'
      }
    }
  },
  'ProcessingThreadsStats': {
    'type': 'object',
    'properties': {
      'internalJobs': {
        '$ref': '#/definitions/ThreadsCount'
      },
      'http': {
        '$ref': '#/definitions/ThreadsCount'
      },
      'processing': {
        '$ref': '#/definitions/ThreadsCount'
      },
      'emails': {
        '$ref': '#/definitions/ThreadsCount'
      }
    }
  },
  'ThreadsStats': {
    'type': 'object',
    'properties': {
      'internalJobs': {
        '$ref': '#/definitions/ThreadsCount'
      },
      'http': {
        '$ref': '#/definitions/ThreadsCount'
      }
    }
  }
}